import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import fileReducer from './file_reducer';
import projectReducer from './project_reducer';
import dayReducer from './day_reducer';
import ikReducer from './ik_reducer';
import expenseReducer from './expense_reducer';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  user: userReducer,
  file: fileReducer,
  project: projectReducer,
  expense: expenseReducer,
  day: dayReducer,
  ik: ikReducer
});

export default rootReducer;
