import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { loginUser } from '../../actions/auth';
import logo from '../../assets/images/logo.png';
import { CLIENT_ROOT_URL } from './../../actions/index';

const form = reduxForm({
  form: 'login',
});

class Login extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    if (this.props.authenticated) {
      window.location.href = `${CLIENT_ROOT_URL}`;
    }
    this.state = {
      open: false,
    };
  }

  handleFormSubmit(formProps) {
    this.props.loginUser(formProps);
  }

  componentDidUpdate(prevProps) {
    var _this = this
    if (this.props.authenticated !== prevProps.authenticated) {
      this.setState({
        open: true,
      });
      setTimeout(function(){
        window.location.href = `${CLIENT_ROOT_URL}`;
      }, 500);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <span><strong>Error!</strong> {this.props.errorMessage}</span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    var classLeft = "left-login"
    if(this.state.open){
      classLeft+=" open"
    }
    var classForm = "form-login"
    if(this.state.open){
      classForm+=" open"
    }

    return (
      <div className="page-login">
        <div className="right-login">
          <img className="logo" src={logo}/>
          <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className={classForm}>
            {this.renderAlert()}
            {/*<h1>Login</h1>*/}
            <div className="row">
              <label className="" htmlFor="email">Email</label>
              <Field name="email" placeholder="email" id="email" className="form-control" component="input" type="email" />
            </div>
            <div className="row">
              <label className="" htmlFor="password">Password</label>
              <Field name="password" placeholder="password" id="password" className="form-control" component="input" type="password" />
            </div>
            <button type="submit" className="btn btn-submit">Log in</button>
            <Link to="/forgot-password" style={{display:"block",marginTop:"10px"}}>Forgot Password ?</Link>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, { loginUser })(form(Login));
