import { CREATE_PROJECT, FETCH_PROJECT, FETCH_PROJECTS, UPDATE_PROJECT, DELETE_PROJECT, PROJECT_ERROR, FETCH_LIST, RESET_PROJECTS } from '../actions/types';

const INITIAL_STATE = { project: null, projects: [], list:[], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_PROJECT:
      return { ...state, message: action.payload.message, error: '' };
    case FETCH_PROJECT:
      return { ...state, project: action.payload.project, message:'', error: '' };
    case FETCH_PROJECTS:
      return { ...state, projects: action.payload.projects, project:null, message:'', error: '' };
    case UPDATE_PROJECT:
      return { ...state, message: action.payload.message, error: '' };
    case DELETE_PROJECT:
      return { ...state, message: action.payload.message, error: '' };
    case PROJECT_ERROR:
      return { ...state, error: action.payload };
    case RESET_PROJECTS:
      return INITIAL_STATE;
    case FETCH_LIST:
      return { ...state, list: {
        cities : action.payload.cities,
        clients : action.payload.clients,
        expertises : action.payload.expertises
      }, message:'', error: '' };
  }

  return state;
}
