import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PlanningModal from "./planningModal";
import _ from 'lodash'

import { publicHolidays, months, hexToRgb, getDaysArray } from './../helpers'
import { updateDay, resetMessage } from '../../actions/day';
import { fetchUsersPlanning, resetUser } from '../../actions/user';

class PlanningView extends Component {

    constructor(props) {
        super(props);

        var startDate = new Date();
        startDate.setMonth(startDate.getMonth());
        startDate.setDate(1);
        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        var planningDays = getDaysArray(startDate, endDate)

        this.state = {
            selectedDay:null,
            usersDays:[],
            startDate:startDate,
            endDate:endDate,
            planningDays:planningDays
        };
    }

    componentDidMount(){
        this.props.resetUser()
        this.setScroll(new Date())
        this.props.fetchUsersPlanning(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString())
    }

    componentDidUpdate(prevProps){
      var _this = this
      if(JSON.stringify(this.props.users) !== JSON.stringify(prevProps.users)){
        var usersDays = [null].concat(_.compact(_this.props.users))
        this.setState({
          selectedDay:null,
          usersDays:usersDays
        })
      }
      if(this.props.message && (this.props.message === 'Day successfully updated' || this.props.message === 'Day successfully created' || this.props.message === 'Day successfully removed')){
        this.props.resetMessage()
        this.props.fetchUsersPlanning(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString())
      }
    }

    loadMore(number){
      var endDate = this.state.endDate
      var startDate = this.state.startDate
      var newDate = null

      if(number === -1){
          newDate = this.state.startDate
          newDate.setMonth(startDate.getMonth() - 1);
          var planningDays = getDaysArray(newDate, endDate)

          var displayDate = new Date(planningDays[0].date)
          document.getElementById("display-date").innerHTML = '<span style={{color:"#b1473f"}}><b>'+new Date(displayDate).getFullYear()+'</b> | <span>'+months[new Date(displayDate).getMonth()].label+'</span></span>'

          this.setState({
              startDate:newDate,
              planningDays:planningDays
          })
      }else if(number === 1){
          newDate = this.state.endDate
          newDate.setMonth(endDate.getMonth() + 1);
          var planningDays = getDaysArray(startDate, newDate)

          this.setState({
              endDate:newDate,
              planningDays:planningDays
          })
      }

      this.setScroll(newDate)
      this.props.fetchUsersPlanning(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString())
    }

    handlePlanningScroll(e){
      var display = document.getElementById("display-date")
      var displayDate = new Date(this.state.planningDays[Math.floor(e.currentTarget.scrollLeft/(37))].date)
      display.innerHTML = '<span style={{color:"#b1473f"}}><b>'+new Date(displayDate).getFullYear()+'</b> | <span>'+months[new Date(displayDate).getMonth()].label+'</span></span>'
    }

    setScroll(date){
      var _this = this
      setTimeout(function(){
          var currentDate = new Date(Date.UTC(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate()))
          var day = currentDate.getDay(),
              diff = currentDate.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
          var monday = new Date(currentDate.setDate(diff));
          var foundIndex = _.findIndex(_this.state.planningDays, function(d){
              return d.date.getTime() === monday.getTime()
          })
          var elm = document.getElementById("container-days")
          elm.scrollLeft = foundIndex*(37)
      },1000);
    }

    setUserDay(day, date, user, isWeekend, isPublicHolidays, isHomeOffice, e) {
        e.preventDefault();
        if(isWeekend || isPublicHolidays){ return }
        if(this.state.selectedDay){
          this.setState({
            selectedDay : null
          })
        }else {
          const dayElement = e.currentTarget
          const boundingClientRect = dayElement.getBoundingClientRect();
          const absoluteTop = boundingClientRect.top + window.pageYOffset;
          const absoluteLeft = boundingClientRect.left + window.pageXOffset;
          this.setState({
            selectedDay:{
              day:day,
              date:date,
              user:user,
              position:{
                left:absoluteLeft,
                top:absoluteTop
              }
            }
          })
        }
    };

    getDayStyle(day, date, isHomeOffice){
        var obj = {}

        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate()-1);
        if(new Date(date).getTime() < new Date(currentDate).getTime()){
            obj.opacity = 0.3
        }

        obj.width = "30px"

        if(day && day.project){
            var color = day.project.color
            if(this.props.project && day.project._id !== this.props.project._id){
              color = "#121316"
            }
            
            obj.backgroundColor = color
            obj['--red'] = (color ? hexToRgb(color).r : null)
            obj['--green'] = (color ? hexToRgb(color).g : null)
            obj['--blue'] = (color ? hexToRgb(color).b : null)
            obj.border = "solid 1px "+color
        }

        return obj
    }

    getDayContent(day, isWeekend, isPublicHolidays, isHomeOffice){
        if(isWeekend || isPublicHolidays){
          return <span><i className="fas fa-smile-beam"></i></span>
        }else if(day && day.dayType === "holiday"){
          if(day.subtype === "Autre"){
            return <span><i className="fas fa-cat"></i></span>
          }else if(day.subtype === "Sans solde"){
            return <span><i className="fas fa-mug-hot"></i></span>
          }else if(day.subtype === "Congé payé"){
            return <span><i className="fas fa-umbrella-beach"></i></span>
          }else{
            return <span><i className="fas fa-smile-beam"></i></span>
          }
        }else if(isHomeOffice){
            return <span><i className="fas fa-home"></i></span>
        }else{
          return
        }
    }

    dragstart_handler(slot, ev) {
      ev.dataTransfer.setData("application/dom-id", ev.target.id);
      ev.dataTransfer.setData("application/slot-id", slot._id);
      ev.dataTransfer.dropEffect = "copy";
     }

    dragover_handler(ev) {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy"
     }
    
     drop_handler(ev) {
      ev.preventDefault();
      
      var slotId = ev.dataTransfer.getData("application/slot-id");
      var newSlotId = ev.target.id.split('_')[3]
      var newUserId = ev.target.id.split('_')[2]
      var newDate = ev.target.id.split('_')[1]
      
      if(newSlotId){ return }

      this.props.updateDay({
        date:newDate,
        user:newUserId,
      }, slotId)
     }

    renderDay(user, day){
        if(!user || !day){ return }
        var render = []
        var _this = this
        var key = day.date.toISOString()
        var ts = new Date().getTime()
        var isWeekend = (new Date(day.date).getDay() === 6 || new Date(day.date).getDay() === 0)
        var isPublicHolidays = (_.map(publicHolidays, function(d){return new Date(d).toString()}).indexOf(new Date(day.date).toString()) !== -1)
        var isHomeOffice = user.homeofficeObj && user.homeofficeObj[key] && user.homeofficeObj[key].length > 0

        var customClass = "day"
        if((!user.holidaysObj || !user.holidaysObj[key]) && (!user.busyDaysObj || !user.busyDaysObj[key]) && !isWeekend && !isPublicHolidays){
            customClass += " empty"
        }
        if(isWeekend){
            customClass += " weekend"
        }
        if(isPublicHolidays){
            customClass += " publicHoliday"
        }

        var slots = []

        if(user.busyDaysObj && user.busyDaysObj[key]){
            var objs = _.map(user.busyDaysObj[key], function(obj){
                return { ... obj, dayType:'busy'}
            })
            slots = slots.concat(objs)
        }
        if(user.holidaysObj && user.holidaysObj[key]){
            var objs = _.map(user.holidaysObj[key], function(obj){
                return { ... obj, dayType:'holiday'}
            })
            slots = slots.concat(objs)
        }

        var isHalf = _.find(slots, function(s){
            return s.period === "am" || s.period === "pm"
        })
        if(isHalf){
            customClass += " half"
        }

        var dayStyle = {pointerEvents:"none"}

        if(!slots.length){

          //Empty Day
          return <div className="day-content" onDrop={this.drop_handler.bind(this)} onDragOver={this.dragover_handler.bind(this)}>
              <a
                key={"day_"+day.date.toISOString()+"_"+user._id}
                id={'day_'+day.date.toISOString()+"_"+user._id}
                className={customClass}
                style={this.getDayStyle(null, day.date)}
                onClick={this.setUserDay.bind(this, null, day.date, user, isWeekend, isPublicHolidays, isHomeOffice)}
              >
                <span style={dayStyle}>{this.getDayContent(null, isWeekend, isPublicHolidays, isHomeOffice)}</span>
              </a>
          </div>
        }

        
        _.each(slots, function(slot, i){
            var activeClass = ""
            if(slot.dayType === "holiday"){
                activeClass += " busy"
            }else if(slot.dayType === "busy"){
                activeClass += " active"
            }

            var draggable = "false"
            if(_this.props.profile.role === 'Admin'){
              draggable = "true"
            }else if(user._id === _this.props.profile._id && slot.dayType === "holiday"){
              draggable = "true"
            }

            //Day
            render.push(
              <a
                key={"day_"+day.date.toISOString()+"_"+user._id+"_"+slot._id+"-"+ts}
                id={'day_'+day.date.toISOString()+"_"+user._id+"_"+slot._id}
                className={customClass+activeClass}
                style={_this.getDayStyle(slot, day.date)}
                onClick={_this.setUserDay.bind(_this, slot, day.date, user, isWeekend, isPublicHolidays, isHomeOffice)}
                draggable={draggable}
                onDragStart={_this.dragstart_handler.bind(_this, slot)}
              >
                  <span style={dayStyle}>{_this.getDayContent(slot, isWeekend, isPublicHolidays, isHomeOffice)}</span>
              </a>
            )
        })
        if(slots.length === 1 && isHalf){
            //Half Day Empty
            var activeClass = " empty"
            render.push(
              <a
                key={"day_"+day.date.toISOString()+"_"+user._id}
                id={'day_'+day.date.toISOString()+"_"+user._id}
                className={customClass+activeClass}
                style={this.getDayStyle(null, day.date)}
                onClick={this.setUserDay.bind(this, null, day.date, user, isWeekend, isPublicHolidays, isHomeOffice)}
              >
                <span style={dayStyle}>{this.getDayContent(null, isWeekend, isPublicHolidays, isHomeOffice)}</span>
            </a>)
        }

        return <div className="day-content">
            {render}
        </div>

    }

  render() {

    return (
      <>
        {this.props.users && this.props.users.length ? (
            <>
              <a onClick={(e) => this.setScroll(new Date())} style={{float:'right', marginLeft:10}}>
                <i className="fas fa-calendar-day"></i> Aujourd'hui
              </a>

              <p id="display-date">...</p>

              {/*USERS*/}
              <div className="container-planning" style={{marginTop:10}}>
                <div className="container-users">
                  {this.state.usersDays.map((user, i) =>
                      <div key={'user'+i}>
                          {user ? (
                              <div style={{display:'flex', alignItems: 'center', height: 35, marginBottom: 5}}>
                                  <div className="user-thumbnail" style={{backgroundColor:user.profile.color}}>
                                      {user.profile.firstName.charAt(0)}
                                  </div>
                                  <p className="user-name hideOnMobile">{user.profile.firstName}</p>
                              </div>
                          ):(
                              <div style={{display:'flex', alignItems: 'center', height: 35, marginBottom: 5}}>
                              </div>
                          )}

                    </div>
                  )}
                </div>
                <div className="container-days" id="container-days" onScroll={(e) => this.handlePlanningScroll(e)}>
                  <a className="load" onClick={(e) => this.loadMore(-1)}><i className="fas fa-angle-left"></i></a>
                  <div>
                      {this.state.usersDays.map((user, i) =>
                        <div key={'userdays'+i} style={{display:'flex', alignItems: 'center', height: 40}}>
                          <div className="days" style={{flexDirection:'row', flexWrap:'nowrap'}}>
                            {this.state.planningDays.map((day, j) =>
                                <div key={'userday'+i+j}>
                                {user ? (
                                    this.renderDay(user, day)
                                ) : (
                                    <p id={'day-'+day.date} style={{width: 32, marginRight: 5, textAlign: 'center', color:"#999999"}}>
                                        <span>{new Date(day.date).getDate()}</span>
                                    </p>
                                )}
                                </div>
                            )}

                          </div>
                        </div>
                      )}
                  </div>
                  <a className="load" onClick={(e) => this.loadMore(1)}><i className="fas fa-angle-right"></i></a>
                </div>
              </div>
              {this.state.selectedDay ? (  
                <PlanningModal
                  date={this.state.selectedDay.date}
                  user={this.state.selectedDay.user}
                  day={this.state.selectedDay.day} 
                  position={this.state.selectedDay.position}
                />
              ) : (
                null
              )}
            </>
          ) : (
            <div style={{textAlign: 'center', fontSize: '36px', color: '#fa5c4f', marginTop: '50px'}}>
              <i className="fas fa-fan fa-spin"></i>
            </div>
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    users: state.user.users,
    message: state.day.message,
    error: state.day.error,
  };
}

export default withRouter(connect(mapStateToProps, {fetchUsersPlanning, updateDay, resetMessage, resetUser})(PlanningView));
