import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";
import { fetchProjects } from '../../actions/project';
import ProjectObjectives from "./projectObjectives";
import Select from 'react-select';
import fileSaver from 'file-saver';
import axios from 'axios';
import { API_URL } from '../../actions/index';

class Project extends Component {

  constructor(props) {
    super(props);
    const { params, fetchProjects } = this.props;
    fetchProjects();
    this.state = {
      projects: []
    };
  }

  componentDidUpdate(prevProps){
    var _this = this
    if(this.props.projects && this.props.projects.length && this.state.projects.length === 0) {
      this.setState({
        projects:_this.props.projects
      })
    }
  }

  downloadFile(){
    axios.get(API_URL+'/project/export', {
      responseType: 'arraybuffer',
      headers: { Authorization: localStorage.getItem('token') },
    })
    .then((response) => {
        const dirtyFileName = response.headers['content-disposition'];
        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
        var  fileName = dirtyFileName.match(regex)[3];

        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fileSaver.saveAs(blob, fileName);
    })
    .catch((error) => {
      console.log(error)
    });
}

  handleSelectedChange(e,i) {
    var _this = this
    var foundCities = _.filter(e, function(eContent){
      return eContent.category === 'Ville'
    })
    var foundClients = _.filter(e, function(eContent){
      return eContent.category === 'Client'
    })
    var foundYears = _.filter(e, function(eContent){
      return eContent.category === 'Années'
    })
    var foundUsers = _.filter(e, function(eContent){
      return eContent.category === 'Responsable'
    })
    var projects = _.filter(_this.props.projects, function(p){
      var value = true
      if(foundCities.length){
        if(_.map(foundCities, 'label').indexOf(p.city) === -1){
          value = false
        }
      }
      if(foundClients.length){
        if(_.map(foundClients, 'label').indexOf(p.client) === -1){
          value = false
        }
      }
      if(foundYears.length){
        if(_.map(foundYears, 'label').indexOf(new Date(p.date).getFullYear()) === -1){
          value = false
        }
      }
      if(foundUsers.length){
        if(_.map(foundUsers, 'value').indexOf(p.user._id) === -1){
          value = false
        }
      }
      return value
    })
    this.setState({
      projects : projects
    })
  }

  renderProjects() {
    var _this = this
    var projects = _.cloneDeep(this.state.projects)
    projects = _.sortBy(projects, function(p){
        return new Date(p.date).getTime()
    }).reverse()
    if (projects && projects.length) {
      return (
        <div className="lines">
          {projects.map((data, i) =>
            _this.renderProject(data, i)
          )}
        </div>
      );
    }
  }

  renderProject(project, i) {
    var type = "fas fa-shapes"
    var year = new Date(project.date).getFullYear()
    var color = "#d9d9d9"

    if(project.user.profile.color){
      color = project.user.profile.color
    }
    var timestamp = new Date().getTime()

    var amountInvoice = 0
    var amountPaid = 0

    if(project.invoices && project.invoices.length){
      amountInvoice = _.sum(_.map(project.invoices, function(i){return i.priceHT}))
      var invoicesPaid = _.filter(project.invoices, function(i){return i.paid === true})
      amountPaid = _.sum(_.map(invoicesPaid, function(i){return i.priceHT}))
    }

    var expense = _.sum(_.map(project.expenses, function(e){
        return e.priceHT
    }))

    var amount = null
    if(project.priceHT){
        amount = project.priceHT
    }
    if(amountInvoice >= amount){
        amountInvoice = amount
    }
    if(amountPaid >= amount){
        amountPaid = amount
    }

    var widthPaid = amountPaid*100/amount
    var widthInvoice = (amountInvoice - amountPaid)*100/amount
    var widthLeft = (amount - amountInvoice)*100/amount

    if(project.priceHT){
        amount = project.priceHT - expense
    }

    return (
      <Link className="line project" to={"/project/"+project._id} key={'project'+i}>
        <div style={{display:'inline-block', verticalAlign: 'top'}} key={timestamp+'project'+i}>
          <i className={type} style={{color:project.color}}></i>
        </div>
        <div className="user-thumbnail" style={{backgroundColor:color, verticalAlign: 'top', marginTop:4}}>
          {project.user && project.user.profile.firstName && project.user.profile.firstName.charAt(0)}
        </div>
        <p className='line-name'><span style={{fontWeight:'bold'}}>{year} - {project.client}</span> - {project.name}</p>

        {amount && amount > 0 ?(
            <div style={{float: "right"}} className="hideOnMobile">
                <span style={{
                    fontWeight:'bold',
                    color: '#fff',
                    textAlign: 'center',
                    width: 130,
                    display: 'block',
                    lineHeight: '25px'
                }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)} HT</span>
                <div className="price-lines">
                    <div className="price-line price-line-paid" style={{width:widthPaid+'%'}}>
                    </div>
                    <div className="price-line price-line-invoice" style={{width:widthInvoice+'%'}}>
                    </div>
                    <div className="price-line" style={{width:widthLeft+'%'}}>
                    </div>
                </div>
            </div>
        ):(
            null
        )}
      </Link>
    );
  }

  render() {
    var _this = this
    var projects = this.props.projects

    var btnClass = "btn headerBtn"
    var titleClass = "page-title"
    var containerClass = "container"

    var cities = _.uniq(_.map(_this.state.projects, 'city'))
    var clients = _.uniq(_.map(_this.state.projects, 'client'))
    var years = _.uniq(_.map(_this.state.projects, function(p){return new Date(p.date).getFullYear()}))

    var options = [
      {
        label: 'Ville',
        options: _.map(cities, function(c){return { value: c, label: c, category:'Ville' }}),
      },
      {
        label: 'Client',
        options: _.map(clients, function(c){return { value: c, label: c, category:'Client' }}),
      },
      {
        label: 'Années',
        options: _.map(years, function(c){return { value: c, label: c, category:'Années' }}),
      },
      {
        label: 'Responsable',
        options: [{
            value: "5e58eb32dc27d6cd1db2ef13",
            label: "Heidi",
            category:'Responsable'
        },{
            value: "5e6251ec8642230d5e8930e0",
            label: "Nicolas",
            category:'Responsable'
        }],
      }
    ];

    var sum = _.sumBy(this.state.projects, 'priceHT')

    return (
      <div className="page-container">
        
        <h1 className={titleClass}><span className="title-number">{this.state.projects.length}</span> <ContainerTranslation id={"project.title"}/> - <span className="title-number">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(sum)}</span></h1>

        {this.props.profile.role === "Admin" ? (
          <>
            <Link className={btnClass} style={{float:'right'}} to={"/project/add/new"}><i className="fas fa-plus"></i><span><ContainerTranslation id='project.new'/></span></Link>
            <a className={btnClass} style={{float:'right', marginRight:10}} onClick={(e) => this.downloadFile()}><i className="fas fa-download"></i><span><ContainerTranslation id='user.export'/></span></a>
            <ProjectObjectives projects={projects}/>
          </>
        ):(
            null
        )}

        <div className={containerClass} style={{marginTop: '30px', padding:0}}>
            <div style={{maxWidth:300}}>
              <Select
                className="Select"
                placeholder="Filtrer par ..."
                options={options}
                isMulti
                onChange={ (e) => this.handleSelectedChange(e) }
              />
             </div>
          {this.renderProjects()}
        </div>
        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects: state.project.projects,
    message: state.project.message,
    profile: state.user.profile
  };
}

export default connect(mapStateToProps, {fetchProjects})(Project);
