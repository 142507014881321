import { getData, postData, putData, deleteData, CLIENT_ROOT_URL } from './index';
import { CREATE_PROJECT, FETCH_PROJECTS, FETCH_PROJECT, UPDATE_PROJECT, DELETE_PROJECT, PROJECT_ERROR,FETCH_LIST, RESET_PROJECTS } from './types';

//= ===============================
// Project actions
//= ===============================

export function createProject(project) {
  const data = project;
  const url = '/project';
  return dispatch => postData(CREATE_PROJECT, PROJECT_ERROR, true, url, dispatch, data)
}

export function fetchProjects(current) {
  let url = '/project';
  if(current){
    url += '?current=true'
  }
  return dispatch => getData(FETCH_PROJECTS, PROJECT_ERROR, true, url, dispatch);
}

export function fetchProject(id) {
  const url = '/project/'+id;
  return dispatch => getData(FETCH_PROJECT, PROJECT_ERROR, true, url, dispatch);
}

export function updateProject(project, id) {
  const url = '/project/'+id
  let data = project
  return dispatch => putData(UPDATE_PROJECT, PROJECT_ERROR, true, url, dispatch, data);
}

export function deleteProject(project) {
  const url = '/project/'+project;
  return dispatch => deleteData(DELETE_PROJECT, PROJECT_ERROR, true, url, dispatch);
}

export function fetchList() {
  const url = '/project/list';
  return dispatch => getData(FETCH_LIST, PROJECT_ERROR, true, url, dispatch);
}

export function resetProjets() {
  return dispatch => dispatch({
    type: RESET_PROJECTS,
    payload: null,
  });
}