import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { browserHistory, withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import Select from 'react-select'
import _ from 'lodash'

import { createIk, fetchIk, updateIk } from '../../actions/ik';
import { createExpense, fetchExpense, updateExpense } from '../../actions/expense';

import { CLIENT_ROOT_URL } from './../../actions/index';

class ExpenseAdd extends Component {

  constructor(props) {
    super(props);
    const { params, createIk, fetchIk, updateIk, createExpense, fetchExpense, updateExpense } = this.props;

    this.state = {
      id:this.props.match.params.id,
      type:{
          label:"Indémnités kilométriques",
          value:"ik"
      },
      ik:{
          date: "",
          distance: 0,
          description: ""
      },
      expense:{
          date: "",
          priceHT: 0,
          description: "",
          file:""
      },
      loading:false
    };
  }

  componentDidMount(){
      const { fetchIk, fetchExpense } = this.props;
      if(this.state.id !== "new"){
        fetchIk(this.state.id)
        fetchExpense(this.state.id)
      }
  }

  componentDidUpdate(prevProps){
    if(!prevProps || !prevProps.ik || (this.state.id !== "new" && !this.state.date && !this.state.distance && !this.state.description)){
      if(this.props.ik){
        this.setState({
          ik: {
              date: this.props.ik.date,
              distance: this.props.ik.distance,
              description: this.props.ik.description
          }
        })
      }
    }else{
      if(this.props.ik && this.props.ik._id !== prevProps.ik._id){
        this.setState({
            ik: {
                date: this.props.ik.date,
                distance: this.props.ik.distance,
                description: this.props.ik.description
            }
        })
      }
    }
    if (this.props.messageIk && this.props.messageIk === 'Ik created !') {
        window.location.href = CLIENT_ROOT_URL+'/expense'
    }
    if (this.props.messageExpense && this.props.messageExpense === 'Expense created !') {
        window.location.href = CLIENT_ROOT_URL+'/expense'
    }
  }

  sendData() {
    var _this = this
    var objToReturn=this.state.ik
    this.setState({
      loading:true
    })
    if(this.state.id !== "new"){
      this.props.updateIk(objToReturn, _this.state.id)
    }else{
      this.props.createIk(objToReturn)
    }
  };

  renderContainer() {
    var _this = this

    return (
      <div className="container" style={{textAlign:'left', marginTop:0}}>
          {this.state.id === "new" ? (
            <div className="back-btn">
              <Link to="/expense"><i className="fas fa-arrow-left"></i></Link>
            </div>
            ) : (
                <div className="back-btn">
                  <Link to={"/expense/"+this.state.id}><i className="fas fa-arrow-left"></i></Link>
                </div>
            )}
        <h2 style={{
          marginTop: '16px',
          marginBottom: 0,
          verticalAlign: 'top',
          display: 'inline-block'
      }}>
          {this.state.id !== "new" && this.props.ik ? (
            <span style={{fontWeight:'normal'}}>Modifier la dépense</span>
          ) : (
              <>
              {this.state.type.value === "ik" ? (
                  <span style={{fontWeight:'normal'}}>Nouvelle indemnité kilométrique</span>
              ):(
                  <span style={{fontWeight:'normal'}}>Nouvelle dépense</span>
              )}
              </>
          )}
        </h2>
        <br/>
        {/*<label className="" htmlFor="type">Type</label>
        <Select
          className="Select"
          name="type"
          options={[{
              label:"Indémnités kilométriques",
              value:"ik"
          },{
              label:"Frais",
              value:"expense"
          }]}
          value={this.state.type}
          multi={false}
          placeholder={'Select the type...'}
          onChange={ (e) => this.setState({type:e}) }
        />*/}
        {this.state.type.value === "ik" ? (
            <>
                <div>
                    <div className="col half">
                        <label className="" htmlFor="date">Date</label>
                        <input name="date" type="date" value={this.state.ik.date} placeholder="Date du déplacement..." style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                      }} onChange={ (e) => this.setState({ik:{...this.state.ik, date:e.currentTarget.value}}) }/>
                    </div>
                    <div className="col half">
                    <label className="" htmlFor="distance">Distance</label>
                    <input name="distance" type="number" value={this.state.ik.distance} placeholder="Distance en kilomètres..." style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                  }} onChange={ (e) => this.setState({ik:{...this.state.ik, distance:e.currentTarget.value}}) }/>
                    </div>
                </div>
                <div>
                    <label className="" htmlFor="description">Description</label>
                    <input name="description" type="text" value={this.state.ik.description} placeholder="Raison du déplacement..." style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                  }} onChange={ (e) => this.setState({ik:{...this.state.ik, description:e.currentTarget.value}}) }/>
                </div>
            </>
        ):(
            <>
            </>
        )}
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
        {this.state.id !== "new" ? (
          <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"expense.edit"}/></a>
        ) : (
          <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"expense.valid"}/></a>
        )}
      </div>
    );
  }

  renderAlert() {
    if (this.props.message) {
      var _this = this
      return (
        <div className="message" style={{marginLeft: '120px', width: 'calc(100% - 320px)', textAlign:'center'}}>
          {this.props.message}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container view-container">
          {this.renderContainer()}
          {this.renderAlert()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ik: state.ik.ik,
    expense: state.expense.expense,
    messageIk: state.ik.message,
    messageExpense: state.expense.message
  };
}

export default withRouter(connect(mapStateToProps, {createIk, fetchIk, updateIk, createExpense, fetchExpense, updateExpense})(ExpenseAdd));
