import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProfile } from '../../actions/index';
import { Link } from 'react-router';
import ContainerTranslation from "../partials/containerTranslation";
import EditProfile from './edit-profile';

class Profile extends Component {

  componentWillMount() {
    // Fetch user data prior to component mounting
    const profileId = JSON.parse(localStorage.getItem('user'))._id
    this.props.fetchProfile(profileId);
  }

  render() {
    return (
      <div>
        <div className="page-container">
          <div className="col-2-3">
            <h1 className="page-title"><ContainerTranslation id={"profile.information"}/></h1>
            <EditProfile
            email = {this.props.profile.email}/>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
  };
}

export default connect(mapStateToProps, { fetchProfile })(Profile);
