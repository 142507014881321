import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";
import fileSaver from 'file-saver';
import { fetchExpenses } from '../../actions/expense';
import { fetchIks } from '../../actions/ik';
import axios from 'axios';
import { API_URL } from '../../actions/index';
import { months } from './../helpers'
import _ from 'lodash'

const currentUser = localStorage.getItem('user');

class Expense extends Component {

  constructor(props) {
    super(props);
    const { params, fetchExpenses, fetchIks } = this.props;
    fetchExpenses();
    fetchIks();
  }

  downloadFile(){
      axios.get(API_URL+'/user/export', {
        responseType: 'arraybuffer',
        headers: { Authorization: localStorage.getItem('token') },
      })
      .then((response) => {
          const dirtyFileName = response.headers['content-disposition'];
          const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
          var  fileName = dirtyFileName.match(regex)[3];

          var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(blob, fileName);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  renderLine(item, i) {
    var type = "fas fa-receipt"

    if(item.distance){
        type = "fas fa-car"
    }
    var dateDispayed = new Date(item.date).getDate()+" "+months[new Date(item.date).getMonth()].label+" "+new Date(item.date).getFullYear()
    return (
      <a className="line user" key={'expense'+i}>
      {/*<Link className="line user" to={"/expense/"+item._id} key={'expense'+i}>*/}
        <div style={{display:'inline-block', verticalAlign: 'top'}}>
          <i className={type}></i>
        </div>
        <div className="user-thumbnail" style={{backgroundColor:item.user.profile.color, verticalAlign: 'top', marginTop:4}}>
          {item.user.profile.firstName && item.user.profile.firstName.charAt(0)}
        </div>
        <p className='line-name'>
            <span style={{fontWeight:'bold', marginRight:10}}>{dateDispayed}</span>
            {item.distance && <span style={{color:'#999999', marginRight:10}}>{item.distance} km</span>}
            <span>{item.description}</span>
        </p>
      </a>
    );
  }

  render() {

    var btnClass = "btn headerBtn"
    var titleClass = "page-title"
    var containerClass = "container lines"

    var items = this.props.iks.concat(this.props.expenses)

    var _this = this
    var iks = []
    if(items){
        iks = {}
        _.each(items, function (item){
            var year = new Date(item.date).getFullYear()
            if(iks[year]){
              iks[year].iks.push(item)
              iks[year].distance += item.distance
            }else{
                iks[year] = {
                    year : year,
                    iks :[item],
                    distance : item.distance
                }
            }
        })
    }
    iks = Object.values(iks).reverse()
    console.log(iks)

    return (
      <div className="page-container">
        <Link className={btnClass} style={{float:'right'}} to={"/expense/add/new"}><i className="fas fa-plus"></i><span><ContainerTranslation id='expense.new'/></span></Link>
        {/*<a className={btnClass} style={{float:'right', marginRight:10}} onClick={(e) => this.downloadFile()}><i className="fas fa-download"></i><span><ContainerTranslation id='expense.export'/></span></a>*/}
        <h1 className={titleClass}><span className="title-number">{items.length}</span> <ContainerTranslation id={"expense.title"}/></h1>
        
        {iks.map((s, i) =>
            <div style={{marginTop: '50px', padding:0}}>
              <p><b>{s.year}</b> - {Math.round(s.distance*100)/100} km</p>
              <div className={containerClass} style={{padding:0}}>
              {s.iks.map((data, j) =>
                  _this.renderLine(data, j)
              )}
              </div>
            </div>
          )}
        
        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    iks: state.ik.iks,
    expenses: state.expense.expenses,
    message: state.user.message,
  };
}

export default connect(mapStateToProps, {fetchExpenses, fetchIks})(Expense);
