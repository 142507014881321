import { CREATE_IK, FETCH_IK, FETCH_IKS, UPDATE_IK, DELETE_IK, IK_ERROR } from '../actions/types';

const INITIAL_STATE = { ik: null, iks: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_IK:
      return { ...state, message: action.payload.message, error: '' };
    case FETCH_IK:
      return { ...state, ik: action.payload.ik, message:'', error: '' };
    case FETCH_IKS:
      return { ...state, iks: action.payload.iks, ik:null, message:'', error: '' };
    case UPDATE_IK:
      return { ...state, message: action.payload.message, error: '' };
    case DELETE_IK:
      return { ...state, message: action.payload.message, error: '' };
    case IK_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
