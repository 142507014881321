import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Select from 'react-select';
import ContainerTranslation from "./../partials/containerTranslation";
import _ from 'lodash'

import { fetchProject, updateProject } from '../../actions/project';

class ProjectAddExpense extends Component {

  constructor(props) {
    super(props);
    const { params, fetchProject, updateProject } = this.props;

    this.state = {
      type: "",
      designation: "",
      priceHT: 0,
      loading:false
    };
  }

  componentDidUpdate(prevProps, prevState){
    var _this = this
    if(this.props.message && this.props.message === 'Project successfully updated!') {
      this.props.fetchProject(_this.props.project._id);
      this.props.closePanel()
    }
  }

  handleTypeChange(e){
    this.setState({
      type:e
    })
  }

  handleDesignationChange(e){
    this.setState({
      designation:e.currentTarget.value
    })
  }

  handlePriceHTChange(e){
    this.setState({
      priceHT:e.currentTarget.value
    })
  }

  closePanel() {
    this.props.closePanel()
  }

  sendData() {
    if(this.state.type && this.state.designation && this.state.priceHT && this.props.project){
      var projectId = this.props.project._id
      var expenses = _.cloneDeep(this.props.project.expenses)
      expenses.push({
        priceHT:this.state.priceHT,
        type:this.state.type.value,
        designation:this.state.designation,
        paid:false
      })
      this.props.updateProject({
        expenses:expenses
      }, projectId)
    }
  };

  renderContainer() {
    var _this = this

    return (
      <div className="container" style={{textAlign:'center', marginTop:0}}>
        <label className="" htmlFor="url">Type de la dépense</label>
        <Select
          className="Select"
          value={this.state.type}
          placeholder="Type..."
          options={[{
              value:'prestation',
              label:'Prestation'
          },{
              value:'materiel',
              label:'Matériel'
          }]}
          onChange={ (e) => this.handleTypeChange(e) }
        />
        <label className="" htmlFor="url">Désignation de la dépense</label>
        <input name="designation" type="text" value={this.state.designation} placeholder="Désignation..." onChange={ (e) => this.handleDesignationChange(e) } style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}/>
        <label className="" htmlFor="url">Montant de la facture</label>
        <input name="priceHT" type="text" value={this.state.priceHT} placeholder="Montant HT..." onChange={ (e) => this.handlePriceHTChange(e) } style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}/>
        {this.state.type && this.state.designation && this.state.priceHT ? (
          <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }>
            <i className="fas fa-thumbs-up"></i><ContainerTranslation id={"projectExpense.valid"}/>
          </a>
        ) : (
          null
        )}

        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}

      </div>
    );
  }

  renderAlert() {
    if (this.props.message) {
      return (
        <div className="message">
          {this.props.message}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container add-back">
        <div className="page-container add">
          <div className="page-container-header">
            <a className="add-btn btn" onClick={this.closePanel.bind(this)}><i className="fas fa-times"></i></a>
          </div>
          {this.renderContainer()}
          {this.renderAlert()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.project.project,
    message: state.project.message
  };
}

export default connect(mapStateToProps, {fetchProject, updateProject})(ProjectAddExpense);
