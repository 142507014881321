import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { fetchProfile } from '../../actions/index';
import logo from '../../assets/images/bertier.svg';
import ReactTooltip from 'react-tooltip'
import ContainerTranslation from "../partials/containerTranslation";

class SidebarTemplate extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    if(localStorage.getItem('token')){
      this.props.fetchProfile();
    }
    this.state = {
      open: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.authenticated !== prevProps.authenticated) {
      this.setState({
        open: true,
      });
    }
  }

  isRole(roleToCheck) {
    const userRole = this.props.profile.role
    if (userRole == roleToCheck) {
      return true;
    }
    return false;
  }

  renderLinksAdmin() {
    return [
      <li key={`${5}header`} className={(this.props.location.startsWith("/user") ? 'active hideOnMobile' : 'hideOnMobile')}>
        <Link to="/user"><i className="fas fa-user-astronaut"></i><ContainerTranslation id={"header.user"}/></Link>
      </li>,
      <li key={`${6}header`} className={(this.props.location.startsWith("/project") ? 'active' : '')}>
        <Link to="/project"><i className="fas fa-shapes showOnMobile"></i><ContainerTranslation id={"header.project"}/></Link>
      </li>,
      <li key={`${7}header`} className={(this.props.location.startsWith("/expense") ? 'active hideOnMobile' : 'hideOnMobile')}>
        <Link to="/expense"><i className="fas fa-receipt showOnMobile"></i><ContainerTranslation id={"header.expense"}/></Link>
      </li>,
    ];
  }

  renderLinksOwner() {
    return [
      <li key={`${6}header`} className={(this.props.location.startsWith("/project") ? 'active' : '')}>
        <Link to="/project"><i className="fas fa-shapes showOnMobile"></i><ContainerTranslation id={"header.project"}/></Link>
      </li>,
    ];
  }

  renderLinks() {
    return [
      <li key={`${1}header`} className={(this.props.location === "/" ? 'active hideOnMobile' : 'hideOnMobile')}>
        <Link to="/"><i className="fas fa-home showOnMobile"></i><ContainerTranslation id={"header.home"}/></Link>
      </li>,
      <li key={`${2}header`} className={(this.props.location.startsWith("/planning") ? 'active' : '')}>
        <Link to="/planning"><i className="fas fa-calendar-alt showOnMobile"></i><ContainerTranslation id={"header.planning"}/></Link>
      </li>,
      <li key={`${3}header`} className={(this.props.location.startsWith("/salary") ? 'active' : '')}>
        <Link to="/salary"><i className="fas fa-file-invoice-dollar"></i><ContainerTranslation id={"header.salary"}/></Link>
      </li>,
    ];
  }

  renderUsersLinks() {
    var profile = ""
    if(!this.props.profile){
      return null
    }
    return [
      <li key={`${1}header`} className={(this.props.location.startsWith("/profile") ? 'active' : '')} style={{textAlign: 'left', marginLeft: 28}}>
        <div style={{verticalAlign: 'top', marginTop:10, display: 'inline-block'}}>
            <Link to="/logout">
              <i className="fas fa-power-off" aria-hidden="true" style={{color:'#FF0000'}}></i>
            </Link>
        </div>
        <Link to="/profile">
            <span style={{display:'inline-block', textAlign:'left'}}>
                {this.props.profile.profile.firstName}<br/>
                <span style={{fontWeight:"bold"}}>{this.props.profile.profile.lastName.toUpperCase()}</span>
            </span>
        </Link>
      </li>,
    ];
  }

  render() {
    if(this.props.authenticated && this.props.profile && this.props.profile.role && !this.props.location.startsWith("/logout")){
      var className = "sidebar"
      if(this.state.open){
        className+=" open"
      }

      return (
        <div className={className}>
          <nav className="navbar">
            <Link className="navbar-brand" to="/"><img src={logo} alt="logo"/><span>BERTHIER</span></Link>
            <ul className="navbar-profile hideOnMobile">
              {this.renderUsersLinks()}
            </ul>
            <ul className="navbar-center">
                {this.renderLinks()}
                {this.props.profile.role === "Owner" ? (
                  <div className='navbar-admin'>
                    {this.renderLinksOwner()}
                  </div>
                    
                ):(
                    null
                )}
                {this.props.profile.role === "Admin" ? (
                  <div className='navbar-admin'>
                    {this.renderLinksAdmin()}
                  </div>
                    
                ):(
                    null
                )}
            </ul>
          </nav>
          <ReactTooltip className='tooltip' place="left" id="logout" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
        </div>
      );
    }else{
      return null
    }
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    profile: state.user.profile
  };
}

export default connect(mapStateToProps, {fetchProfile})(SidebarTemplate);
