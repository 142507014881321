import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash'
import { months } from './../helpers'
import { updateDay, createDay, deleteDay } from '../../actions/day';

class PlanningModal extends Component {

  constructor(props) {
    super(props);
  }

  selectOption(type, subtype, e) {
    if(this.props.day && this.props.day.dayType === "busy"){ return }

    if(subtype === "delete" && this.props.day){
      this.props.deleteDay(this.props.day._id)
    }else{
      var obj = {
        date:this.props.date.toISOString(),
        period:"all",
        project:null,
        subtype:subtype,
        type:type,
        user:this.props.user._id
      }
      if(this.props.day){
        this.props.updateDay(obj, this.props.day._id)
      }else{
        this.props.createDay(obj)
      }
    }
  };

  selectOffice(foundHomeOffice) {
    if(foundHomeOffice){
      this.props.deleteDay(foundHomeOffice._id)
    }else{
      var obj = {
        date:this.props.date.toISOString(),
        period:"all",
        project:null,
        subtype:null,
        type:'homeoffice',
        user:this.props.user._id
      }
      this.props.createDay(obj)
    }
  }

  selectPeriod(period, e) {
    this.props.updateDay({
      period:period
    }, this.props.day._id)
  };

  render() {
    var homeOffices = this.props.user.homeoffice
    var date = this.props.date.toISOString()
    var foundHomeOffice = _.find(homeOffices, function(o){
      return o.date === date
    })
    var isEditable = false
    if(this.props.profile.role === 'Admin'){
      isEditable = true
    }
    if(this.props.profile.role === 'Member' && this.props.user._id === this.props.profile._id){
      isEditable = true
    }
    if(this.props.profile.role === 'Owner' && this.props.user._id === this.props.profile._id){
      isEditable = true
    }
    return (
        <div 
          className="container"
          style={{
            borderRadius: '8px',
            boxShadow: '5px 5px 10px #1c1c1c, -5px -5px 10px #202020',
            position: 'absolute',
            background: '#222429',
            maxWidth: 200,
            top:this.props.position.top,
            left:this.props.position.left
          }}
        >
            
            {!foundHomeOffice && this.props.day && this.props.day.dayType !== "busy" && isEditable ? (
                <a
                  key={"day-modal-delete"}
                  onClick={this.selectOption.bind(this, 'holiday', 'delete')}
                  className='holiday'
                  data-for='holiday'
                  style={{color:"red", float: "right", marginTop: '-3px'}}
                  data-tip={'Supprimer le jour'}
                >
                  <span><i className="fas fa-trash"></i></span>
                </a>
              ) : (
                null
              )}

            <p style={{color:"#999999", marginTop:0}}>
              {new Date(this.props.date).getDate()+" "+months[new Date(this.props.date).getMonth()].label+" "+new Date(this.props.date).getFullYear()}
            </p>

            {this.props.day && this.props.day.dayType === "busy" && this.props.day.project ? (
              <>
                <h3 style={{margin:0}}>{this.props.day.project.client}</h3>
                <p style={{marginTop:0}}>{this.props.day.project.name}</p>
              </>
            ) : (
              null
            )}

            {/*Office*/}
            {(!this.props.day || this.props.day.dayType === "busy") && isEditable ? (
            <>
              {!foundHomeOffice ? (
                <a
                  key={"day-modal-home"}
                  onClick={this.selectOffice.bind(this, foundHomeOffice)}
                  className='holiday'
                  style={{paddingLeft: 0, textDecoration:"none"}}
                >
                  <span><i className="fas fa-home"></i> Télétravail</span>
                </a>
              ):(
                <a
                  key={"day-modal-office"}
                  onClick={this.selectOffice.bind(this, foundHomeOffice)}
                  className='holiday'
                  style={{paddingLeft: 0, textDecoration:"none"}}
                >
                  <span><i className="fas fa-building"></i> À l'agence</span>
                </a>
              )}
            </>
            ) : (
              null
            )}

            {/*Durée*/}
            {this.props.day && this.props.day.dayType !== "busy" && isEditable ? (
            <>
              <p style={{marginBottom:3}}>Durée</p>
              <a
                key={"day-modal-morning"}
                className='holiday'
                style={{paddingLeft:0}}
                onClick={this.selectPeriod.bind(this, 'am')}
              >
                <span>Matin</span>
              </a>
              <a
                key={"day-modal-full"}
                className='holiday'
                onClick={this.selectPeriod.bind(this, 'all')}
              >
                <span>Journée</span>
              </a>
              <a
                key={"day-modal-afternoon"}
                className='holiday'
                onClick={this.selectPeriod.bind(this, 'pm')}
              >
                <span>Après-midi</span>
              </a>
              <br/>
            </>
            ) : (
              null
            )}  

            {/*Congés*/}
            {!foundHomeOffice && (!this.props.day || this.props.day.dayType !== "busy") && isEditable ? (
            <>  
              <p style={{marginBottom:3}}>Congés</p>
              <a
                key={"day-modal-ss"}
                onClick={this.selectOption.bind(this, 'holiday', 'Sans solde')}
                className='holiday'
                data-for='holiday'
                data-tip={'Sans solde'}
              >
                <span><i className="fas fa-mug-hot"></i></span>
              </a>
              <a
                key={"day-modal-cp"}
                onClick={this.selectOption.bind(this, 'holiday', 'Congé payé')}
                className='holiday'
                data-for='holiday'
                data-tip={'Congé payé'}
              >
                <span><i className="fas fa-umbrella-beach"></i></span>
              </a>
              <a
                key={"day-modal-a"}
                onClick={this.selectOption.bind(this, 'holiday', 'Autre')}
                className='holiday'
                data-for='holiday'
                data-tip={'Autre'}
                style={{marginRight:15}}
              >
                <span><i className="fas fa-cat"></i></span>
              </a>
            </>
            ) : (
              null
            )}
            
            
            <ReactTooltip className='tooltip' place="bottom" id="holiday" type="dark" effect="solid" getContent={(dataTip) => dataTip}/>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile
  };
}

export default withRouter(connect(mapStateToProps, {updateDay, createDay, deleteDay})(PlanningModal));
