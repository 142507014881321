import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";
import fileSaver from 'file-saver';
import { fetchUsers } from '../../actions/user';
import axios from 'axios';
import { API_URL } from '../../actions/index';

const currentUser = localStorage.getItem('user');

class User extends Component {

  constructor(props) {
    super(props);
    const { params, fetchUsers } = this.props;
    fetchUsers();
  }

  renderUsers() {
    var _this = this
    if (this.props.users && this.props.users.length) {
      return (
        <div className="lines">
          {this.props.users.map((data, i) =>
            _this.renderUser(data, i)
          )}
        </div>
      );
    }
  }

  downloadFile(){
      axios.get(API_URL+'/user/export', {
        responseType: 'arraybuffer',
        headers: { Authorization: localStorage.getItem('token') },
      })
      .then((response) => {
          const dirtyFileName = response.headers['content-disposition'];
          const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
          var  fileName = dirtyFileName.match(regex)[3];

          var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(blob, fileName);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  renderUser(user, i) {
    var type = "fas fa-user-astronaut"

    return (
      <Link className="line user" to={"/user/"+user._id} key={'user'+i}>
        <div style={{display:'inline-block', verticalAlign: 'top'}}>
          <i className={type}></i>
        </div>
        <div className="user-thumbnail" style={{backgroundColor:user.profile.color, verticalAlign: 'top', marginTop:4}}>
          {user.profile.firstName && user.profile.firstName.charAt(0)}
        </div>
        <p className='line-name'>{user.profile.firstName} {user.profile.lastName}</p>
      </Link>
    );
  }

  render() {

    var btnClass = "btn headerBtn"
    var titleClass = "page-title"
    var containerClass = "container"

    return (
      <div className="page-container">
        <Link className={btnClass} style={{float:'right'}} to={"/user/add/new"}><i className="fas fa-plus"></i><span><ContainerTranslation id='user.new'/></span></Link>
        <a className={btnClass} style={{float:'right', marginRight:10}} onClick={(e) => this.downloadFile()}><i className="fas fa-download"></i><span><ContainerTranslation id='user.export'/></span></a>
        <h1 className={titleClass}><span className="title-number">{this.props.users.length}</span> <ContainerTranslation id={"user.title"}/></h1>
        <div className={containerClass} style={{marginTop: '50px', padding:0}}>
          {this.renderUsers()}
        </div>
        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.user.users,
    message: state.user.message,
  };
}

export default connect(mapStateToProps, {fetchUsers})(User);
