import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { browserHistory, withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'

import { createProject, fetchProject, updateProject, fetchList } from '../../actions/project';
import { fetchUsers } from '../../actions/user';

import { CLIENT_ROOT_URL } from './../../actions/index';

class ProjectAdd extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;

    this.state = {
        projectId:this.props.match.params.id,
        name: "",
        city: "",
        user:"",
        client: "",
        tasksURL: "",
        driveURL: "",
        uiURL: "",
        priceHT: 0,
        production: {
            '5e58eb32dc27d6cd1db2ef13':0,
            '5e6251ec8642230d5e8930e0':0
        },
        expertise: "",
        date: "",
        color: "#121316",
        loading:false
    };
  }

  componentDidMount(){
      const { fetchProject, fetchList, fetchUsers } = this.props;
      if(this.state.projectId !== "new"){
        fetchProject(this.state.projectId)
      }
      if(!this.props.list || !this.props.list.clients){
        fetchList()
      }
      if(!this.props.users || !this.props.users.length){
        fetchUsers()
      }
  }

  componentDidUpdate(prevProps){
    if(
        ((!prevProps || !prevProps.project) && this.props.project) ||
        (this.props.project && this.props.project._id !== prevProps.project._id) ||
        (!this.state.name && this.props.project)
    ){
        var production = {
            '5e58eb32dc27d6cd1db2ef13':0,
            '5e6251ec8642230d5e8930e0':0
        }
        if(this.props.project.production && this.props.project.production['5e58eb32dc27d6cd1db2ef13']){
            production['5e58eb32dc27d6cd1db2ef13'] = this.props.project.production['5e58eb32dc27d6cd1db2ef13']
        }
        if(this.props.project.production && this.props.project.production['5e6251ec8642230d5e8930e0']){
            production['5e6251ec8642230d5e8930e0'] = this.props.project.production['5e6251ec8642230d5e8930e0']
        }
      this.setState({
        name: this.props.project.name,
        client: this.props.project.client,
        city: this.props.project.city,
        color:  ((this.props.project.color) ? this.props.project.color : "#121316"),
        tasksURL: this.props.project.tasksURL,
        driveURL: this.props.project.driveURL,
        uiURL: this.props.project.uiURL,
        user: this.props.project.user._id,
        priceHT: this.props.project.priceHT,
        production: production,
        expertise: this.props.project.expertise,
        date: this.props.project.date.substring(0,10)
      })
    }
    if (this.props.message && this.props.message === 'Project created !') {
        window.location.href = CLIENT_ROOT_URL+'/project'
    }
  }

  handleNameChange(e){
    this.setState({
      name:e.currentTarget.value
    })
  }

  handleColorChange(e){
    this.setState({
      color:e.currentTarget.value
    })
  }

  handleTasksURLChange(e){
    this.setState({
      tasksURL:e.currentTarget.value
    })
  }

  handleDriveURLChange(e){
    this.setState({
      driveURL:e.currentTarget.value
    })
  }

  handleUIURLChange(e){
    this.setState({
      uiURL:e.currentTarget.value
    })
  }

  handlePriceChange(e){
    this.setState({
      priceHT:e.currentTarget.value
    })
  }

  handleProdHeidiChange(e){
      var _this = this
      var production = _.cloneDeep(_this.state.production)
      production['5e58eb32dc27d6cd1db2ef13'] = e.currentTarget.value
    this.setState({
      production:production
    })
  }

  handleProdNicolasChange(e){
      var _this = this
      var production = _.cloneDeep(_this.state.production)
      production['5e6251ec8642230d5e8930e0'] = e.currentTarget.value
    this.setState({
      production:production
    })
  }

  handleClientChange(e){
    this.setState({
      client:e
    })
  }

  handleCityChange(e){
    this.setState({
      city:e
    })
  }

  handleDateChange(e){
    this.setState({
      date:e.currentTarget.value
    })
  }

  handleExpertiseChange(e){
    this.setState({
      expertise:e
    })
  }

  handleUserChange(e){
    this.setState({
      user:e
    })
  }

  sendData() {
    var _this = this

    var objToReturn={
      name: this.state.name,
      date: this.state.date,
      city: this.state.city,
      user: this.state.user,
      color: this.state.color,
      tasksURL: this.state.tasksURL,
      driveURL: this.state.driveURL,
      uiURL: this.state.uiURL,
      priceHT: this.state.priceHT,
      production: this.state.production,
      client: this.state.client,
      expertise: this.state.expertise
    }
    this.setState({
      loading:true
    })

    if(this.state.projectId !== "new"){
      this.props.updateProject(objToReturn, _this.state.projectId)
    }else{
      this.props.createProject(objToReturn)
    }
  };

  renderContainer() {
    var _this = this

    var expertises = []
    var expertise = null
    if(this.props.list){
      expertises = _.map(_this.props.list.expertises, function(e){
        return { value: e, label: e }
      })
      if(this.state.expertise){
        expertise = _.find(expertises, function(e){
          return _this.state.expertise === e.value
        })
      }
    }

    var users = []
    var user = null
    if(this.props.users){
      users = _.map(_this.props.users, function(p){
        return {
          value:p._id,
          label:p.profile.firstName+" "+p.profile.lastName
        }
      })
      if(this.state.user){
        user = _.find(users, function(p){
          return _this.state.user === p.value
        })
      }
    }

    var clients = []
    var client = null
    if(this.props.list){
      clients = _.map(_this.props.list.clients, function(c){
        return { value: c, label: c }
      })
    }
    if(this.state.client){
      client = _.find(clients, function(c){
        return _this.state.client === c.value
      })
    }

    var cities = []
    var city = null
    if(this.props.list){
      cities = _.map(_this.props.list.cities, function(c){
        return { value: c, label: c }
      })
    }
    if(this.state.city){
      city = _.find(cities, function(c){
        return _this.state.city === c.value
      })
    }

    return (
          <div className="container" style={{textAlign:'left', marginTop: 0, paddingTop: '10px'}}>
              {this.state.projectId === "new" ? (
                <div className="back-btn">
                  <Link to="/project"><i className="fas fa-arrow-left"></i></Link>
                </div>
                ) : (
                    <div className="back-btn">
                      <Link to={"/project/"+this.state.projectId}><i className="fas fa-arrow-left"></i></Link>
                    </div>
                )}
            <h2 style={{
              marginTop: '16px',
              marginBottom: 0,
              verticalAlign: 'top',
              display: 'inline-block'
          }}>
              {this.state.projectId !== "new" && this.props.project ? (
                <span style={{fontWeight:'normal'}}>Modifier le projet</span>
              ) : (
                <span style={{fontWeight:'normal'}}>Nouveau projet</span>
              )}
            </h2>
            <br/>
            <div>
                <div className="col col-2-3">
                    <div className="col col-2-3">
                        <label className="" htmlFor="name">Nom</label>
                        <input type="text" name="name" value={this.state.name} placeholder="Nom du projet..." onChange={ (e) => this.handleNameChange(e) } style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}/>
                    </div>
                    <div className="col col-1-3">
                        <label className="" htmlFor="color">Couleur</label>
                        <input type="color" id="color" name="color" value={this.state.color} onChange={ (e) => this.handleColorChange(e) } style={{
                          display: 'block',
                          marginTop: 8,
                          marginBottom: 15
                        }}/>
                    </div>
                </div>
                <div className="col col-1-3">
                    <label className="" htmlFor="client">Client</label>
                    <CreatableSelect
                      className="Select"
                      value={client}
                      placeholder="Nom du client..."
                      options={clients}
                      isSearchable
                      isClearable
                      onChange={ (e) => this.handleClientChange(e.value) }
                    />
                </div>
            </div>
            <div>
                <div className="col col-2-3">
                    <label className="" htmlFor="user">Responsable</label>
                    <Select
                      className="Select"
                      name="user"
                      options={users}
                      value={user}
                      multi={false}
                      placeholder={'Select the type...'}
                      onChange={ (e) => this.handleUserChange(e.value) }
                    />
                </div>
                <div className="col col-1-3">
                    <label className="" htmlFor="priceHT">Montant HT €</label>
                    <input type="number" name="priceHT" value={this.state.priceHT} placeholder="Montant du projet..." onChange={ (e) => this.handlePriceChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                </div>
            </div>
            <div>
                <div className="col col-2-3">
                    <label className="" htmlFor="expertise">Expertise</label>
                    <CreatableSelect
                      className="Select"
                      value={expertise}
                      placeholder="Expertise du projet..."
                      options={expertises}
                      isSearchable
                      isClearable
                      onChange={ (e) => this.handleExpertiseChange(e.value) }
                    />
                </div>
                <div className="col col-1-3">
                    <label className="" htmlFor="date">Date de signature</label>
                    <input name="date" type="date" value={this.state.date} placeholder="Date..." onChange={ (e) => this.handleDateChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                </div>
            </div>
            <div>
                <div className="col col-2-3">
                    <label className="" htmlFor="city">Ville</label>
                    <CreatableSelect
                      className="Select"
                      value={city}
                      placeholder="Ville du client..."
                      options={cities}
                      isSearchable
                      isClearable
                      onChange={ (e) => this.handleCityChange(e.value) }
                    />
                </div>
                <div className="col col-1-3">
                    <label className="" htmlFor="prodHeidi">Production Heidi HT €</label>
                    <input type="number" name="prodHeidi" value={this.state.production['5e58eb32dc27d6cd1db2ef13']} placeholder="Part de production..." onChange={ (e) => this.handleProdHeidiChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                </div>
            </div>
            <div>
                <div className="col col-2-3">
                    <label className="" htmlFor="tasksURL">URL Jira</label>
                    <input type="text" name="tasksURL" value={this.state.tasksURL} placeholder="URL vers le projet..." onChange={ (e) => this.handleTasksURLChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                    <label className="" htmlFor="tasksURL">URL Drive</label>
                    <input type="text" name="driveURL" value={this.state.driveURL} placeholder="URL vers le Google Drive..." onChange={ (e) => this.handleDriveURLChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                    <label className="" htmlFor="tasksURL">URL Figma</label>
                    <input type="text" name="uiURL" value={this.state.uiURL} placeholder="URL vers le projet Figma..." onChange={ (e) => this.handleUIURLChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                </div>
                <div className="col col-1-3">
                    <label className="" htmlFor="prodNicolas">Production Nicolas HT €</label>
                    <input type="number" name="prodNicolas" value={this.state.production['5e6251ec8642230d5e8930e0']} placeholder="Part de production..." onChange={ (e) => this.handleProdNicolasChange(e) } style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}/>
                </div>
            </div>

            {this.props.error ? (
              <div className='message error' style={{marginTop:'20px'}}>
                {this.props.error}
              </div>
            ) : (
              null
            )}
            {this.state.projectId !== "new" ? (
              <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"project.edit"}/></a>
            ) : (
              <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"project.valid"}/></a>
            )}
      </div>
    );
  }

  renderAlert() {
    if (this.props.message) {
      var _this = this
      setTimeout(function(){
        _this.setState({
          isTryingToEdit:false
        })
      }, 5000);
      return (
        <div className="message confirm" style={{marginLeft: '120px', width: 'calc(100% - 320px)'}}>
          {this.props.message}
        </div>
      );
    }
    if (this.props.error) {
      var _this = this
      setTimeout(function(){
        _this.setState({
          isTryingToEdit:false
        })
      }, 5000);
      return (
        <div className="message error" style={{marginLeft: '120px', width: 'calc(100% - 320px)'}}>
          {this.props.error}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container view-container">
          {this.renderContainer()}
          {this.renderAlert()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.project.project,
    list: state.project.list,
    users: state.user.users,
    message: state.project.message,
    error: state.project.error
  };
}

export default withRouter(connect(mapStateToProps, {createProject, fetchProject, updateProject, fetchList, fetchUsers})(ProjectAdd));
