import { getData, postData, putData, deleteData, CLIENT_ROOT_URL } from './index';
import { CREATE_IK, FETCH_IKS, FETCH_IK, UPDATE_IK, DELETE_IK, IK_ERROR } from './types';

//= ===============================
// Ik actions
//= ===============================

export function createIk(ik) {
  const data = ik;
  const url = '/ik';
  return dispatch => postData(CREATE_IK, IK_ERROR, true, url, dispatch, data)
}

export function fetchIks() {
  const url = '/ik';
  return dispatch => getData(FETCH_IKS, IK_ERROR, true, url, dispatch);
}

export function fetchIk(id) {
  const url = '/ik/'+id;
  return dispatch => getData(FETCH_IK, IK_ERROR, true, url, dispatch);
}

export function updateIk(ik, id) {
  const url = '/ik/'+id
  let data = ik
  return dispatch => putData(UPDATE_IK, IK_ERROR, true, url, dispatch, data);
}

export function deleteIk(ik) {
  const url = '/ik/'+ik;
  return dispatch => deleteData(DELETE_IK, IK_ERROR, true, url, dispatch);
}
