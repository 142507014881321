import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { browserHistory, withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import Select from 'react-select'
import _ from 'lodash'

import { createUser, fetchUser, updateUser } from '../../actions/user';

import { CLIENT_ROOT_URL } from './../../actions/index';

class UserAdd extends Component {

  constructor(props) {
    super(props);
    const { params, createUser, fetchUser, updateUser } = this.props;

    this.state = {
      userId:this.props.match.params.id,
      firstName: "",
      lastName: "",
      email: "",
      color: "",
      loading:false
    };
  }

  componentDidMount(){
      const { fetchUser } = this.props;
      if(this.state.userId !== "new"){
        fetchUser(this.state.userId)
      }
  }

  componentDidUpdate(prevProps){
    if(!prevProps || !prevProps.user || (this.state.userId !== "new" && !this.state.email)){
      if(this.props.user){
        this.setState({
          firstName: this.props.user.profile.firstName,
          lastName: this.props.user.profile.lastName,
          email: this.props.user.email,
          color: this.props.user.profile.color
        })
      }
    }else{
      if(this.props.user && this.props.user._id !== prevProps.user._id){
        this.setState({
          firstName: this.props.user.profile.firstName,
          lastName: this.props.user.profile.lastName,
          email: this.props.user.email,
          color: this.props.user.profile.color
        })
      }
    }
    if (this.props.message && this.props.message === 'user register !') {
        window.location.href = CLIENT_ROOT_URL+'/user'
    }
  }

  handleFirstNameChange(e){
    this.setState({
      firstName:e.currentTarget.value
    })
  }

  handleLastNameChange(e){
    this.setState({
      lastName:e.currentTarget.value
    })
  }

  handleEmailChange(e){
    this.setState({
      email:e.currentTarget.value
    })
  }

  handleColorChange(e){
    this.setState({
      color:e.currentTarget.value
    })
  }

  sendData() {
    var _this = this
    var objToReturn={
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      color: this.state.color
    }
    this.setState({
      loading:true
    })
    if(this.state.userId !== "new"){
      this.props.updateUser({profile:objToReturn}, _this.state.userId)
    }else{
      this.props.createUser(objToReturn)
    }
  };

  renderContainer() {
    var _this = this

    return (
      <div className="container" style={{textAlign:'left', marginTop:0}}>
          {this.state.userId === "new" ? (
            <div className="back-btn">
              <Link to="/user"><i className="fas fa-arrow-left"></i></Link>
            </div>
            ) : (
                <div className="back-btn">
                  <Link to={"/user/"+this.state.userId}><i className="fas fa-arrow-left"></i></Link>
                </div>
            )}
        <h2 style={{
          marginTop: '16px',
          marginBottom: 0,
          verticalAlign: 'top',
          display: 'inline-block'
      }}>
          {this.state.userId !== "new" && this.props.user ? (
            <span style={{fontWeight:'normal'}}>Modifier le membre</span>
          ) : (
            <span style={{fontWeight:'normal'}}>Nouveau membre</span>
          )}
        </h2>
        <br/>
        <div>
            <div className="col half">
                <label className="" htmlFor="firstName">Prénom</label>
                <input name="firstName" type="text" value={this.state.firstName} placeholder="Prénom du collaborateur..." onChange={ (e) => this.handleFirstNameChange(e) } style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}/>
            </div>
            <div className="col half">
                <label className="" htmlFor="lastName">Nom</label>
                <input name="lastName" type="text" value={this.state.lastName} placeholder="Nom du collaborateur..." onChange={ (e) => this.handleLastNameChange(e) } style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}/>
            </div>
        </div>
        <div>
            <div className="col col-2-3">
                <label className="" htmlFor="email">Email</label>
                <input name="email" type="email" value={this.state.email} placeholder="Email du collaborateur..." onChange={ (e) => this.handleEmailChange(e) } style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}/>
            </div>
            <div className="col col-1-3">
                <label className="" htmlFor="color">Couleur</label>
                <input type="color" id="color" name="color" value={this.state.color} onChange={ (e) => this.handleColorChange(e) } style={{
                  display: 'block',
                  marginTop: 8,
                  marginBottom: 15
                }}/>
            </div>
        </div>
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
        {this.state.userId !== "new" ? (
          <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"user.edit"}/></a>
        ) : (
          <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"user.valid"}/></a>
        )}
      </div>
    );
  }

  renderAlert() {
    if (this.props.message) {
      var _this = this
      return (
        <div className="message" style={{marginLeft: '120px', width: 'calc(100% - 320px)', textAlign:'center'}}>
          {this.props.message}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container view-container">
          {this.renderContainer()}
          {this.renderAlert()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    message: state.user.message
  };
}

export default withRouter(connect(mapStateToProps, {createUser, fetchUser, updateUser})(UserAdd));
