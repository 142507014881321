import React, { Component } from 'react';
import {months} from './../helpers';
import _ from 'lodash';
import { API_URL } from '../../actions/index';

class SalaryList extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
  }

  render() {
      var _this = this
      var salaries = []
      if(this.props.user.salaries){
          salaries = {}
          _.each(_this.props.user.salaries, function (salary){
              var year = new Date(salary.date).getFullYear()
              if(salaries[year]){
                  salaries[year].salaries.push(salary)
              }else{
                  salaries[year] = {
                      year : year,
                      salaries :[salary]
                  }
              }
          })
      }
      salaries = Object.values(salaries).reverse()
      var style = {textAlign:'left', paddingTop: '10px'}
      if(this.props.withTitle){
        style = {textAlign:'left', paddingTop: 0, marginTop: 0}
      }

    return (
      <div className="container" style={style}>
        {this.props.withTitle && <h1 className="page-title" style={{marginBottom:20, marginTop:0}}>Bulletins</h1>}
        {salaries.map((s, i) =>
            <>
                <p><b>{s.year}</b></p>
                <div className="documents">
                {s.salaries.map((data, j) =>
                    <a className="document" key={"salary"+i+j} href={API_URL + "/file/"+data.file+"?token="+localStorage.getItem('token').substring(4)}>
                      <i className="fas fa-file-alt"></i>
                      <p>{months[new Date(data.date).getMonth()].label}<br/>{new Date(data.date).getFullYear()}</p>
                    </a>
                )}
                </div>
            </>
          )}
      </div>
    );
  }
}

export default SalaryList;
