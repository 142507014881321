import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import _ from 'lodash'

import { fetchProject, updateProject } from '../../actions/project';

class ProjectAddInvoice extends Component {

  constructor(props) {
    super(props);
    const { params, fetchProject, updateProject } = this.props;

    this.state = {
      url: "",
      priceHT: 0,
      loading:false,
      sentAt:""
    };
  }

  componentDidUpdate(prevProps, prevState){
    var _this = this
    if(this.props.message && this.props.message === 'Project successfully updated!') {
      this.props.fetchProject(_this.props.project._id);
      this.props.closePanel()
    }
  }
  closePanel() {
    this.props.closePanel()
  }

  sendData() {
    if(this.state.url && this.state.priceHT && this.props.project){
      var projectId = this.props.project._id
      var invoices = _.cloneDeep(this.props.project.invoices)
      invoices.push({
        priceHT:this.state.priceHT,
        url:this.state.url,
        sentAt:this.state.sentAt,
        paid:false
      })
      this.props.updateProject({
        invoices:invoices
      }, projectId)
    }
  };

  renderContainer() {
    var _this = this

    return (
      <div className="container" style={{textAlign:'center', marginTop:0}}>
        <label className="" htmlFor="url">Lien vers la facture</label>
        <input name="url" type="text" value={this.state.url} placeholder="URL..." style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }} onChange={ (e) => this.setState({ url:e.currentTarget.value }) }
        />
        <label className="" htmlFor="priceHT">Montant de la facture</label>
        <input name="priceHT" type="text" value={this.state.priceHT} placeholder="Montant HT..." style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }} onChange={ (e) => this.setState({ priceHT:e.currentTarget.value }) }/>
        <label className="" htmlFor="date">Date de la facture</label>
        <input name="date" type="date" value={this.state.sentAt} placeholder="Date..." style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }} onChange={ (e) => this.setState({ sentAt:e.currentTarget.value }) }/>
        {this.state.url && this.state.priceHT ? (
          <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }>
            <i className="fas fa-thumbs-up"></i><ContainerTranslation id={"invoice.valid"}/>
          </a>
        ) : (
          null
        )}

        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}

      </div>
    );
  }

  renderAlert() {
    if (this.props.message) {
      return (
        <div className="message">
          {this.props.message}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container add-back">
        <div className="page-container add">
          <div className="page-container-header">
            <a className="add-btn btn" onClick={this.closePanel.bind(this)}><i className="fas fa-times"></i></a>
          </div>
          {this.renderContainer()}
          {this.renderAlert()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    project: state.project.project,
    message: state.project.message
  };
}

export default connect(mapStateToProps, {fetchProject, updateProject})(ProjectAddInvoice);
