import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";
import { OBJECTIVE } from './../../actions/index';
import { ResponsiveLine } from '@nivo/line';

const MyResponsiveLine = ({ data }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 30, left: 70 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
        curve="natural"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'CA € HT',
            legendOffset: -60,
            legendPosition: 'middle'
        }}
        //colors={{ scheme: 'nivo' }}
        colors={d => d.color}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableArea={true}
        enableSlices="x"
        crosshairType="left"
        useMesh={true}
        legends={[
            {
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -30,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ],
                itemTextColor: '#999999'
            }
        ]}
        theme={{
          //background: "#222222",
          axis: {
            //fontSize: "14px",
            tickColor: "#eee",
            ticks: {
              line: {
                stroke: "#555555"
              },
              text: {
                fill: "#999999"
              }
            },
            legend: {
              text: {
                fill: "#aaaaaa"
              }
            }
          },
          grid: {
            line: {
              stroke: "#555555"
            }
          }
        }}
    />
)

class ProjectObjectives extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCumul: true
    };
  }

  setCumul(value) {
    this.setState({
      isCumul:value
    })
  };

  render() {
    var projects = this.props.projects
    var _this = this

    var projectsData = []

    const optionsDate = {month: 'long' };

    if(projects && projects.length){

      var allValues = 0

      var rawData = [{
        x:'janvier',
        y:0
      },{
        x:'février',
        y:0
      },{
        x:'mars',
        y:0
      },{
        x:'avril',
        y:0
      },{
        x:'mai',
        y:0
      },{
        x:'juin',
        y:0
      },{
        x:'juillet',
        y:0
      },{
        x:'août',
        y:0
      },{
        x:'septembre',
        y:0
      },{
        x:'octobre',
        y:0
      },{
        x:'novembre',
        y:0
      },{
        x:'décembre',
        y:0
      }]

      var data = _.cloneDeep(rawData)

      _.each(projects, function(p){
        if(new Date(p.date).getFullYear() !== new Date().getFullYear()){
          return
        }
        var foundDate = _.find(data, function(d){
          return d.x === new Date(p.date).toLocaleDateString('fr-FR', optionsDate)
        })
        if(!foundDate){
          return
        }
        var expense = _.sum(_.map(p.expenses, function(e){
            return e.priceHT
        }))
        foundDate.y += (p.priceHT-expense)
      })

      if(this.state.isCumul){
          _.each(data, function(d, i){
            if(i>0){
              d.y = d.y+data[i-1].y
            }
          })

      }

      projectsData = [{
        color:'#fa5c4f',
        id:'Signé '+new Date().getFullYear(),
        data: data
      },{
        color:'#A3B9CE',
        id:'Objectif '+new Date().getFullYear(),
        data:_.map(data, function(p, i){
          if(_this.state.isCumul){
            return {
              x:p.x,
              y:(OBJECTIVE/12*(i+1)).toFixed(2)
            }
          }else{
            return {
              x:p.x,
              y:(OBJECTIVE/12).toFixed(2)
            }
          }
        })
      }]

    }

    return (
      <div>
        {projectsData.length ? (
          <div className="container marginOnMobile" style={{
            height:300,
            fontFamily:"'Open Sans', sans-serif",
            borderRadius: '8px',
            background: '#1c1e22',
            marginTop: 40
          }}>
            {this.state.isCumul ? (
              <a onClick={(e) => this.setCumul(false)} key={new Date().getTime()+'check1'}>
                <i className="far fa-check-square"></i> Cumuler les données
              </a>
            ):(
              <a onClick={(e) => this.setCumul(true)} key={new Date().getTime()+'check2'}>
                <i className="far fa-square"></i> Cumuler les données
              </a>
            )}
            <MyResponsiveLine data={projectsData} key={new Date().getTime()+'line'}/>
          </div>
        ):(
          null
        )}

        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.project.message,
  };
}

export default connect(mapStateToProps, {})(ProjectObjectives);
