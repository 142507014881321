import React, { Component } from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import SidebarTemplate from './partials/sidebar';
import { withLocalize } from "react-localize-redux";
import { createBrowserHistory } from 'history';
import globalTranslations from "./../assets/translations/global.json";

const onMissingTranslation = ({ defaultTranslation }) => {
  return ''
};

class Page extends Component {

  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "French", code: "fr" }
      ],
      translation: globalTranslations,
      options: {
        defaultLanguage: "fr",
        renderToStaticMarkup : false,
        onMissingTranslation
     }
    });

    if(this.props.location.pathname === "/"){
      this.props.history.push('/');
    }

  }

  componentDidMount () {
      this.props.history.listen(() => {
        if (window.swUpdateReady) {
          window.swUpdateReady = false;
          window.stop();
          window.location.reload();
        }
    });
}

  render() {

    return (
      <div className="mainContainer">
        <div>
          {this.props.children}
        </div>
        <SidebarTemplate title="Berthier" location={this.props.location.pathname}/>
      </div>
    );
  }
}

export default withLocalize(Page);
