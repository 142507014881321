import { postData, putData, deleteData } from './index';
import { CREATE_DAY, UPDATE_DAY, DELETE_DAY, DAY_ERROR, RESET_MESSAGE } from './types';

//= ===============================
// Day actions
//= ===============================

export function createDay(day) {
  const data = day;
  const url = '/day';
  return dispatch => postData(CREATE_DAY, DAY_ERROR, true, url, dispatch, data)
}

export function updateDay(day, id) {
  const url = '/day/'+id
  let data = day
  return dispatch => putData(UPDATE_DAY, DAY_ERROR, true, url, dispatch, data);
}

export function deleteDay(day) {
  const url = '/day/'+day;
  return dispatch => deleteData(DELETE_DAY, DAY_ERROR, true, url, dispatch);
}

export function resetMessage() {
  return dispatch => dispatch({
    type: RESET_MESSAGE,
    payload: null,
  });
}