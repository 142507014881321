import React from "react";
import { withLocalize } from "react-localize-redux";
import Select from 'react-select';

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
  /*<ul className="selector">
    {languages.map(lang => (
      <li key={lang.code}>
        <button onClick={() => setActiveLanguage(lang.code)}>
          {lang.name}
        </button>
      </li>
    ))}
  </ul>*/
  <div>
    <Select
      className="Select"
      name="language"
      options={[{value:'en',label:'English'},{value:'fr',label:'Français'}]}
      multi={false}
      placeholder={'Select your language'}
      onChange={ (e) => setActiveLanguage(e.value) }
    />
  </div>
);

export default withLocalize(LanguageToggle);
