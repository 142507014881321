import { CREATE_EXPENSE, FETCH_EXPENSE, FETCH_EXPENSES, UPDATE_EXPENSE, DELETE_EXPENSE, EXPENSE_ERROR } from '../actions/types';

const INITIAL_STATE = { expense: null, expenses: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_EXPENSE:
      return { ...state, message: action.payload.message, error: '' };
    case FETCH_EXPENSE:
      return { ...state, expense: action.payload.expense, message:'', error: '' };
    case FETCH_EXPENSES:
      return { ...state, expenses: action.payload.expenses, expense:null, message:'', error: '' };
    case UPDATE_EXPENSE:
      return { ...state, message: action.payload.message, error: '' };
    case DELETE_EXPENSE:
      return { ...state, message: action.payload.message, error: '' };
    case EXPENSE_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
