import { getData, postData, putData, deleteData } from './index';
import { CREATE_FILE, RESET_FILE, FILE_ERROR } from './types';

//= ===============================
// File actions
//= ===============================

export function createFile(data) {
  const url = '/file';
  var formObj  = new FormData();
  formObj.append('file',data.file)
  return dispatch => postData(CREATE_FILE, FILE_ERROR, true, url, dispatch, formObj);
}

export function resetFile() {
  return dispatch => dispatch({
    type: RESET_FILE,
    payload: null,
  });
}
