import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";
import { fetchProjects, resetProjets } from '../../actions/project';

class Project extends Component {

  constructor(props) {
    super(props);
    this.props.resetProjets()
    this.state = {};
  }

  componentDidMount(){
    this.props.fetchProjects(true)
  }

  componentDidUpdate(prevProps){
    if(this.props.messageProject && this.props.messageProject === 'Project successfully updated'){
      this.props.fetchProjects(true)
    }
    if(this.props.messageDay && this.props.messageDay === 'Day successfully updated'){
      this.props.fetchProjects(true)
    }
  }

  renderProjects(ps) {
    var _this = this
    var projects = _.cloneDeep(ps)
    if(projects && projects.length && this.props.user){
      projects = _.filter(projects, function(p){
        var isIn = false
        _.each(p.days, function(d){
          if(new Date(d.date).getTime() >= new Date().getTime() && d.user._id === _this.props.user._id){
            isIn = true
          }
        })
        return isIn
      })
    }
    projects = _.sortBy(projects, function(p){
        return new Date(p.date).getTime()
    }).reverse()
    if (projects && projects.length) {
      return (
        <div className="lines">
          {projects.map((data, i) =>
            _this.renderProject(data, i)
          )}
        </div>
      );
    }
  }

  renderProject(project, i) {
    var type = "fas fa-shapes"
    var timestamp = new Date().getTime()

    if(!project || !project.users){
      return
    }

    return (
      <div className="line project" key={'project'+i}>
        <div style={{display:'inline-block', verticalAlign: 'top'}} key={timestamp+'project'+i}>
          <i className={type} style={{color:project.color}}></i>
        </div>
        {project.users.map((data, j) =>
            <div className="user-thumbnail" style={{backgroundColor:data.profile.color, verticalAlign: 'top', marginTop:4, marginRight:-5}} key={'project-user'+i+j}>
              {data.profile.firstName && data.profile.firstName.charAt(0)}
            </div>
        )}
        <p className='line-name' style={{marginLeft:20,width: 'calc(100% - 200px)'}}><span style={{fontWeight:'bold'}}>{project.client}</span> - {project.name} - <span style={{color:'#999999'}}>{project.daysCount} jours restants</span></p>
        <div className="tools">
            {project.driveURL && <a className="tool" href={project.driveURL} target="_blank"><i className="fab fa-google-drive"></i></a>}
            {project.tasksURL && <a className="tool" href={project.tasksURL} target="_blank"><i className="fab fa-jira"></i></a>}
            {project.uiURL && <a className="tool" href={project.uiURL} target="_blank"><i className="fab fa-figma"></i></a>}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container" style={{textAlign:'left', paddingTop: 0, marginTop:20, paddingLeft:0, paddingRight:0}}>
        {this.props.user ? (
          <h1 className="page-title" style={{marginBottom:20, marginTop:0}}>Mes projets en cours</h1>
        ) : (
          <h1 className="page-title" style={{marginBottom:20, marginTop:0}}>{this.props.projects.length} projets en cours</h1>
        )}
        <div className="container" style={{marginTop: '10px', padding:0}}>
          {this.renderProjects(this.props.projects)}
        </div>
        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects: state.project.projects,
    messageProject: state.project.message,
    messageDay: state.day.message
  };
}

export default connect(mapStateToProps, {fetchProjects, resetProjets})(Project);
