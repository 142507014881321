import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ContainerTranslation from "./../partials/containerTranslation";
import ReactTooltip from 'react-tooltip';
import ProjectAddQuote from './addQuote'
import ProjectAddInvoice from './addInvoice'
import ProjectAddExpense from './addExpense'
import PlanningView from './../planning/planningView'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'

import { fetchProject, updateProject, deleteProject } from '../../actions/project';
import { createDay, updateDay, deleteDay, resetMessage } from '../../actions/day';
import { months, dayTypes, publicHolidays, hexToRgb } from './../helpers'

import { CLIENT_ROOT_URL } from './../../actions/index';

var daysType = _.map(dayTypes, function(day){
  return {
    value:day,
    label:day
  }
})

class ProjectView extends Component {

  constructor(props) {
    super(props);
    const { fetchProject } = this.props;
    fetchProject(this.props.match.params.id)
    this.state = {
      addInvoice:false,
      addQuote:false,
      addExpense:false,
      addDays:false,
      daysType:null,
      projectDay:null,
      projectTypes:[]
    };
  }

  componentDidMount() {
      const { params } = this.props;
      if (this.props.project) {
          if (this.props.project.days) {
              var types = []
              _.each(this.props.project.days, function(day) {
                  var foundType = _.find(types, function(type) {
                      return type.type === day.type
                  })
                  if (foundType) {
                      foundType.days.push(day)
                  } else {
                      types.push({
                          type: day.type,
                          days: [day]
                      })
                  }
              })
              this.setState({
                  projectTypes:types
              })
          }
      }
      fetchProject(this.props.match.params.id)
  }

  componentDidUpdate(prevProps, prevState) {
      var _this = this
      if ((this.props.messageProject && this.props.messageProject === 'Project successfully updated') ||
        (this.props.messageDay && (this.props.messageDay === 'Day successfully updated' || this.props.messageDay === 'Day successfully created' || this.props.messageDay === 'Day successfully removed'))) {
          if (this.state.projectDay) {
              this.setState({
                  projectDay: null
              })
          }
          this.props.fetchProject(_this.props.project._id);
      }
      if (this.props.messageProject && this.props.messageProject === 'Project successfully removed') {
          window.location.href = CLIENT_ROOT_URL+'/project'
      }
      if ((!prevProps.project || (prevProps.project && this.props.project && JSON.stringify(this.props.project) !== JSON.stringify(prevProps.project))) && (this.props.project && this.props.project.days)) {
          var types = []
          _.each(this.props.project.days, function(day) {
              var foundType = _.find(types, function(type) {
                  return type.type === day.subtype
              })
              if (foundType) {
                  foundType.days.push(day)
              } else {
                  types.push({
                      type: day.subtype,
                      days: [day]
                  })
              }
          })
          this.setState({
              projectTypes:types
          })
      }
  }

  closeModal(data) {
    this.setState({
      addInvoice:false,
      addQuote:false,
      addExpense:false
    })
  };

  addExpense(id, e) {
    e.preventDefault();
    this.setState({
      addExpense:true
    })
  };

  addInvoice(id, e) {
    e.preventDefault();
    this.setState({
      addInvoice:true
    })
  };

  addQuote(id, e) {
    e.preventDefault();
    this.setState({
      addQuote:true
    })
  };

  invoicePaid(id, value, e) {
    var _this = this
    e.preventDefault();
    var invoices = _.cloneDeep(_this.props.project.invoices)
    invoices[id].paid = value
    if(value === false){
        delete invoices[id].paidAt
    }
    this.props.updateProject({
      invoices:invoices
    }, _this.props.project._id)
  };

  clickDate(e) {
    //e.preventDefault();
  };

  changeDate(id, e) {
      var _this = this
      var invoices = _.cloneDeep(_this.props.project.invoices)
      invoices[id].paidAt = e.currentTarget.value
      this.props.updateProject({
        invoices:invoices
      }, _this.props.project._id)
  };

  expensePaid(id, value, e) {
    var _this = this
    e.preventDefault();
    var expenses = _.cloneDeep(_this.props.project.expenses)
    expenses[id].paid = value
    this.props.updateProject({
      expenses:expenses
    }, _this.props.project._id)
  };

  addDays(e) {
    e.preventDefault();
    this.setState({
      addDays:true
    })
  };

  addDay(type) {
    var day = {
      date:null,
      period:"all",
      project:this.props.project._id,
      subtype:type,
      type:'project',
      user:null
    }
    this.props.createDay(day)
    this.setState({
      addDays:false
    })
  };

  closeAddDays(e) {
    this.setState({
      addDays:false
    })
  };

  setDayClass(day){
    var classToReturn = "day"
    if(day.user){
      classToReturn += " empty"
    }
    if(this.state.projectDay && day._id === this.state.projectDay._id){
      classToReturn += " select"
    }
    if(day.period !== "all"){
      classToReturn += " half"
    }
    if(!day.user && (!this.state.projectDay || day._id !== this.state.projectDay)){
      classToReturn += " active"
    }
    return classToReturn
  }

  isEmpty(day){
    if(this.state.projectDay && day._id === this.state.projectDay._id){
      return false
    }
    if(!day.user && (!this.state.projectDay || day._id !== this.state.projectDay)){
      return false
    }
    if(day.user){
      return true
    }
    return false
  }

  selectProjectDay(day, e) {
    e.preventDefault();
    if(day.user && day.date){
      this.props.updateDay({
        date:null,
        user:null,
      }, day._id)
      return
    }

    this.setState({
      projectDay:day
    })
  }

  selectDurationDay(day, isHalf, e) {
    e.preventDefault();
    var period = "all"
    if(isHalf){
      period = "am"
    }
    this.props.updateDay({
      period:period
    }, day._id)

  };

  deleteDay(day){
    this.props.deleteDay(day._id)
  }

  closeDay(day){
    this.setState({
        projectDay:null
    })
  }

  handleDelete(id) {
    var result = window.confirm("Voulez-vous vraiment supprimer ce project ?");
    if (result) {
      this.props.deleteProject(id)
    }
  }

  dragstart_handler(day, ev) {
    ev.dataTransfer.setData("application/dom-id", ev.target.id);
    ev.dataTransfer.setData("application/slot-id", day._id);
    ev.dataTransfer.dropEffect = "copy";
   }

  render() {
    var _this = this

    var users = []
    var user = null
    if(this.props.users){
      users = _.map(_this.props.users, function(p){
        return {
          value:p._id,
          label:p.profile.firstName+" "+p.profile.lastName
        }
      })
      if(this.state.user){
        user = _.find(users, function(p){
          return _this.state.user === p.value
        })
      }
    }

    var usersDays = []

    if(this.props.project && this.props.project.days){
      usersDays = _.map(_this.props.users, function(u){
        var user = _.cloneDeep(u)
        var foundDays = _.filter(_this.props.project.days, function(d){
          return d.user && d.user.toString() === user._id.toString()
        })
        if(foundDays && foundDays.length){
          user.days=foundDays
          return user
        }else{
            return null
        }
      })
      usersDays = _.compact(usersDays)
    }

    if(this.state.userAddedValid){
      var foundUser = _.find(usersDays, function(u){
        return u._id.toString() === _this.state.userAddedValid._id.toString()
      })
      if(!foundUser){
        usersDays.push(this.state.userAddedValid)
      }
    }
    var monthsDisplayed = months.slice(new Date().getMonth()-1, new Date().getMonth()+4)

    var color = "#d9d9d9"
    var userColor = "#d9d9d9"

    if(this.props.project && this.props.project.user.profile.color){
      userColor = this.props.project.user.profile.color
    }

    if(this.props.project && this.props.project.color){
      color = this.props.project.color
    }

    return (
      <div className="page-container view-container">
        {this.props.project ? (
          <div className="container" style={{textAlign:'left', marginTop: 0, paddingTop: '10px'}}>
            {this.props.profile.role === "Admin" ? (
              <div className="tools" style={{marginTop:24}}>
                <Link to={"/project/add/"+this.props.project._id} data-for='toolproject' data-tip="project.edit">
                  <div style={{display:'inline-block'}}>
                    <i className="fa fa-pen" aria-hidden="true"></i>
                  </div>
                </Link>
                <a onClick={this.handleDelete.bind(this, this.props.project._id)} data-for='toolproject' data-tip="project.delete" className="Message-remove">
                    <div style={{display:'inline-block'}}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </div>
                </a>
              <ReactTooltip className='tooltip' id='toolproject' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
            </div>):null}
            <div className="back-btn">
              <Link to="/project"><i className="fas fa-arrow-left"></i></Link>
            </div>
            <h2 style={{
              marginTop: '16px',
              marginBottom: 0,
              verticalAlign: 'top',
              display: 'inline-block'
          }}><span style={{color:color}}><i className="fas fa-shapes"></i></span>
          <div className="user-thumbnail" style={{backgroundColor:userColor, verticalAlign: 'top', marginTop:4, fontSize:'18px'}}>
            {this.props.project.user && this.props.project.user.profile.firstName && this.props.project.user.profile.firstName.charAt(0)}
          </div>{this.props.project.client} - <span style={{fontWeight:'normal'}}>{this.props.project.name}</span></h2>




            {/*PLANNING*/}
            <br/><h1 className="page-title" style={{marginBottom:20, marginTop:20}}>Planning</h1><br/>

            {/*DAYS*/}
            <div className="container-days projetDays">
              {this.state.projectTypes.map((type, i) =>
                <div className="days" key={"days"+i}>
                  <p>{type.type}</p>
                  {type.days.map((day, j) =>
                    <div key={'projectday'+type+j}>
                      {this.isEmpty(day) ? (
                          <div className="day-content">
                            <a style={{color:"#fa5c4f"}} className={this.setDayClass(day)} key={"day"+i+j} onClick={this.selectProjectDay.bind(this, day)}>
                                <div>
                                    <i className="fas fa-undo-alt"></i>
                                </div>
                            </a>
                        </div>

                      ):(
                          <div className="day-content">
                          {this.state.projectDay && this.state.projectDay._id === day._id ? (
                              <div className={this.setDayClass(day)+" open"} key={"dayopen"+i+j+new Date().now}>
                                  <a key={"day"+i+j+"full"} onClick={this.selectDurationDay.bind(this, day, false)} data-for='day' data-tip={'Jour entier'}>
                                    <span><i className="fas fa-star"></i></span>
                                  </a>
                                  <a key={"day"+i+j+"half"} onClick={this.selectDurationDay.bind(this, day, true)} data-for='day' data-tip={'Demi-journée'} style={{marginRight:15}}>
                                    <span><i className="fas fa-star-half-alt"></i></span>
                                  </a>

                                  <a key={"day"+i+j+"delete"} onClick={this.deleteDay.bind(this, day)} data-for='day' data-tip={'Supprimer'}>
                                    <span><i className="fas fa-trash"></i></span>
                                  </a>
                                  <a key={"day"+i+j+"close"} onClick={this.closeDay.bind(this, day)} data-for='day' data-tip={'Fermer'}>
                                    <span><i className="fas fa-times"></i></span>
                                  </a>
                                  <ReactTooltip className='tooltip' place="bottom" id="day" type="dark" effect="solid" getContent={(dataTip) => dataTip}/>
                              </div>
                          ):(
                              <a
                                style={{color:"#fa5c4f", backgroundColor:color}}
                                className={this.setDayClass(day)}
                                key={"day"+i+j}
                                onClick={this.selectProjectDay.bind(this, day)}
                                draggable={"true"}
                                onDragStart={this.dragstart_handler.bind(this, day)}
                              >
                                <span></span>
                              </a>
                          )}
                          </div>
                      )}
                    </div>
                  )}
                  {this.props.profile.role === "Admin" ? (
                    <div className="day-content">
                        <a className="day select" onClick={this.addDay.bind(this, type.type)}>
                          <i className="fas fa-plus"></i>
                        </a>
                    </div>
                  ):null}
                </div>
              )}
            </div>

            {/*ADD DAYS*/}
            {this.state.addDays ? (
              <div>
                <div style={{
                  width:"300px",
                  display:'inline-block'
                }}>
                  <CreatableSelect
                    className="Select"
                    value={this.state.daysType}
                    placeholder="Type de jour..."
                    options={daysType}
                    isSearchable
                    onChange={ (e) => this.addDay(e.value) }
                  />
                </div>
                <a style={{display:'inline-block',marginLeft: '10px'}} onClick={(e) => this.closeAddDays(e.value)}>
                  Annuler
                </a>
              </div>
            ) : (
              <>
                {this.props.profile.role === "Admin" ? (
                  <a onClick={this.addDays.bind(this)} style={{display:'block'}}>
                    <i className="fas fa-plus"></i> Ajouter des jours vendus
                  </a>
                ):null}
              </>
            )}

            {/*USERS*/}
            {this.props.project ? (
                <div style={{marginTop:20}}>
                    <PlanningView project={this.props.project}/>
                </div>
            ) : (
              null
            )}

            {/*DEVIS*/}
            <br/><h1 className="page-title" style={{marginBottom:20, marginTop:20}}>Devis</h1><br/>
            <div className="documents">
              {this.props.project.quote && this.props.project.quote.url ? (
                <a className="document" key={"quote"} href={this.props.project.quote.url} target="_blank">
                  <i className="fas fa-file-contract"></i>
                  <p>{this.props.project.priceHT} € HT</p>
                </a>
              ):(
                <a className="document" onClick={this.addQuote.bind(this, this.props._id)} style={{background:'#fa5c4f', color:"#ffffff"}}>
                  <i className="fas fa-plus"></i>
                  <p style={{color:"#ffffff"}}>Associer le devis</p>
                </a>
              )}
            </div>

            {/*FACTURES*/}
            <br/><h1 className="page-title" style={{marginBottom:20, marginTop:20}}>Factures</h1><br/>
            <div className="documents">
              {this.props.project.invoices && this.props.project.invoices.map((data, i) =>
                <a className="document" key={"invoice"+i} href={data.url} target="_blank">
                  <p>{data.priceHT} € HT</p>
                  {data.paid ? (
                      <>
                      <div onClick={_this.invoicePaid.bind(this, i, false)} key={"invoice"+i+"paid"}><i className="fas fa-check-circle" style={{fontSize:'18px', color:'#80c92b'}}></i></div>
                      <span style={{marginTop:10}}>Payé le :</span>
                      <input type="date" id="paidAt" value={data.paidAt} onClick={_this.clickDate.bind(this)} onChange={_this.changeDate.bind(this, i)}/>
                      </>
                  ) : (
                      <>
                        <div onClick={_this.invoicePaid.bind(this, i, true)} key={"invoice"+i+"unpaid"}><i className="fas fa-check-circle" style={{fontSize:'18px', color:'rgb(207, 208, 210)'}}></i></div>
                      </>
                  )}
                </a>
              )}
              {this.props.profile.role === "Admin" ? (
                <a className="document" onClick={this.addInvoice.bind(this, this.props._id)} style={{background:'#fa5c4f', color:"#ffffff"}}>
                  <i className="fas fa-plus"></i>
                  <p style={{color:"#ffffff"}}>Nouvelle facture</p>
                </a>
              ):null}
            </div>

            {/*DEPENSES*/}
            <br/><h1 className="page-title" style={{marginBottom:20, marginTop:20}}>Dépenses</h1><br/>
            <div className="documents">
              {this.props.project.expenses && this.props.project.expenses.map((data, i) =>
                <a className="document" key={"expense"+i} target="_blank">
                  {/*<i className="fas fa-file-invoice-dollar"></i>*/}
                  <p><span style={{fontWeight:'bold',textTransform: 'capitalize'}}>{data.type}</span><br/>
                  {data.designation}<br/>
                  {data.priceHT} € HT</p>
                  {data.paid ? (
                    <a onClick={_this.expensePaid.bind(this, i, false)} key={"expense"+i+"paid"}><i className="fas fa-check-circle" style={{fontSize:'18px', color:'#80c92b'}}></i></a>
                  ) : (
                    <a onClick={_this.expensePaid.bind(this, i, true)} key={"expense"+i+"unpaid"}><i className="fas fa-check-circle" style={{fontSize:'18px', color:'rgb(207, 208, 210)'}}></i></a>
                  )}

                </a>
              )}
              {this.props.profile.role === "Admin" ? (
                <a className="document" onClick={this.addExpense.bind(this, this.props._id)} style={{background:'#fa5c4f', color:"#ffffff"}}>
                  <i className="fas fa-plus"></i>
                  <p style={{color:"#ffffff"}}>Nouvelle dépense</p>
                </a>
              ):null}
            </div>

          </div>
        ) : (
          null
        )}
        {this.state.addQuote ? (
          <ProjectAddQuote closePanel={this.closeModal.bind(this)} />
        ) : (
          null
        )}
        {this.state.addInvoice ? (
          <ProjectAddInvoice closePanel={this.closeModal.bind(this)} />
        ) : (
          null
        )}
        {this.state.addExpense ? (
          <ProjectAddExpense closePanel={this.closeModal.bind(this)} />
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.user.users,
    project: state.project.project,
    messageProject: state.project.message,
    messageDay: state.day.message,
    profile: state.user.profile
  };
}

export default withRouter(connect(mapStateToProps, {fetchProject, updateProject, deleteProject, createDay, updateDay, deleteDay, resetMessage})(ProjectView));
