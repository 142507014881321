import { getData, postData, putData, deleteData, CLIENT_ROOT_URL } from './index';
import { CREATE_EXPENSE, FETCH_EXPENSES, FETCH_EXPENSE, UPDATE_EXPENSE, DELETE_EXPENSE, EXPENSE_ERROR } from './types';

//= ===============================
// Expense actions
//= ===============================

export function createExpense(expense) {
  const data = expense;
  const url = '/expense';
  return dispatch => postData(CREATE_EXPENSE, EXPENSE_ERROR, true, url, dispatch, data)
}

export function fetchExpenses() {
  const url = '/expense';
  return dispatch => getData(FETCH_EXPENSES, EXPENSE_ERROR, true, url, dispatch);
}

export function fetchExpense(id) {
  const url = '/expense/'+id;
  return dispatch => getData(FETCH_EXPENSE, EXPENSE_ERROR, true, url, dispatch);
}

export function updateExpense(expense, id) {
  const url = '/expense/'+id
  let data = expense
  return dispatch => putData(UPDATE_EXPENSE, EXPENSE_ERROR, true, url, dispatch, data);
}

export function deleteExpense(expense) {
  const url = '/expense/'+expense;
  return dispatch => deleteData(DELETE_EXPENSE, EXPENSE_ERROR, true, url, dispatch);
}
