import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SalaryList from "./salaryList";

import { API_URL } from '../../actions/index';
import { fetchProfile } from '../../actions/index';

class Salary extends Component {

  constructor(props) {
    super(props);
    const { params, fetchProfile } = this.props;
    fetchProfile()
  }

  render() {

    return (
      <div className="page-container">
        <h1 className="page-title">BULLETINS</h1>
        {this.props.user ? (
            <SalaryList user={this.props.user}/>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.profile,
    message: state.user.message
  };
}

export default withRouter(connect(mapStateToProps, {fetchProfile})(Salary));
