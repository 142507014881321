import { CREATE_DAY, UPDATE_DAY, DELETE_DAY, DAY_ERROR, RESET_MESSAGE } from '../actions/types';

const INITIAL_STATE = { message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_DAY:
      return { ...state, message: action.payload.message, error: '' };
    case RESET_MESSAGE:
      return { ...state, message: '', error: '' };  
    case UPDATE_DAY:
      return { ...state, message: action.payload.message, error: '' };
    case DELETE_DAY:
      return { ...state, message: action.payload.message, error: '' };
    case DAY_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
