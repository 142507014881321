import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'
import Project from "./../planning/project";
import _ from 'lodash';
import { dateWithoutTime, formatDate } from './../helpers'

class HomePage extends Component {

  constructor(props) {
    super(props);
    const { params} = this.props;
    this.state = {
      gif:null,
	  meteo:null
    };
  }

  componentDidMount(){

	  var tag = "hello"
	  if(new Date().getDay() === 1){
	  	tag = "monday"
	  }
	  if(new Date().getDay() === 5){
	  	tag = "friday"
	  }
	  if(new Date().getDay() === 6){
	  	tag = "weekend"
	  }
	  if(new Date().getDay() === 0){
	  	tag = "weekend"
	  }

	  fetch("https://api.openweathermap.org/data/2.5/weather?q=Nantes&appid=93fad04125501a6ae6d7ddcf960daaa1&lang=fr&units=metric")
	    .then(response => response.json())
        .then(data => {
			this.setState({
				meteo:{
					wind:Math.round(data.wind.speed*3,6),
					temp:data.main.temp,
					description:data.weather[0].description,
					icon:data.weather[0].icon
				}
			})
			if(data.weather[0].main === 'Clouds'){
				tag += " cloud"
			}
			if(data.weather[0].main === 'Clear'){
				tag += " sun"
			}
			if(data.weather[0].main === 'Atmosphere'){
				tag += " fog"
			}
			if(data.weather[0].main === 'Snow'){
				tag += " snow"
			}
			if(data.weather[0].main === 'Rain'){
				tag += " rain"
			}
			if(data.weather[0].main === 'Thunderstorm'){
				tag += " storm"
			}
			const giphy = {
	  	  		baseURL: "https://api.giphy.com/v1/gifs/",
	  	  		apiKey: "0UTRbFtkMxAplrohufYco5IY74U8hOes",
	  	  		tag: tag,
	  	  		type: "random",
	  	  		rating: "pg-13"
	  	  	};

	  	    let giphyURL = encodeURI(
	  	  		giphy.baseURL +
	  	  			giphy.type +
	  	  			"?api_key=" +
	  	  			giphy.apiKey +
	  	  			"&tag=" +
	  	  			giphy.tag +
	  	  			"&rating=" +
	  	  			giphy.rating
	  	  	);


	      fetch(giphyURL)
	        .then(response => response.json())
	        .then(data => this.setState({gif:data.data.images.original.url}));
        })
        .catch(error => {
            console.log(error)
        });
  }

  render() {
    var profile = ""
    var _this = this
    var foundDay = null
    var foundTomorrowDay = null
    var foundHoliday = null
    if(this.props.profile && this.props.profile._id){
      profile = this.props.profile.profile.firstName
      foundDay = _.find(_this.props.profile.busyDays, function(d){
        var todayValue = dateWithoutTime(new Date()).getTime()
        var dayValue = dateWithoutTime(new Date(d.date)).getTime()
        return todayValue === dayValue
      })
      foundTomorrowDay = _.find(_this.props.profile.busyDays, function(d){
        var tomorrow = new Date();
        tomorrow.setDate(new Date().getDate()+1);
        var currentValue = dateWithoutTime(tomorrow).getTime()
        var dayValue = dateWithoutTime(new Date(d.date)).getTime()
        return currentValue === dayValue
      })
    }
    return (
      <div className="page-container">
        <div className="col half" style={{width:'calc(50% - 100px)'}}>
          <h1><span style={{fontWeight:'normal'}}>Bonjour</span> <span style={{fontWeight:"bold"}}>{profile} !</span></h1>
          <div className="col" style={{padding:0, paddingRight:20}}>
                <img src={this.state.gif} height="150px" style={{
                  boxShadow: 'rgb(36 117 130 / 20%) 0px 5px 9px',
                  borderRadius: '8px'
                }}/>
          </div>
          <div className="col" style={{padding:0}}>
            {this.state.meteo && this.state.meteo.temp ? (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <img src={"https://openweathermap.org/img/wn/"+this.state.meteo.icon+"@2x.png"}/>
                <h2 style={{fontSize: '48px', margin:0, verticalAlign: 'top', marginTop: -30 }}>{Math.round(this.state.meteo.temp)}°</h2>
              </div>
              ):(
                <p>...</p>
              )}
          </div>
          {foundDay ? (
            <div className="container marginOnMobile" style={{
              boxShadow: '5px 5px 10px #1c1c1c, -5px -5px 10px #202020',
              borderRadius: '8px',
              background: '#1c1e22',
              borderLeft: 'solid 10px '+foundDay.project.color
            }}>
              <p>Aujourd'hui <b>{formatDate(foundDay.date)}</b></p>
              <h2>{foundDay.project.client} - <span style={{fontWeight:'normal'}}>{foundDay.project.name}</span></h2>

			    <div className="tools" style={{float:"initial"}}>
	              {foundDay.project.driveURL && <a className="tool" href={foundDay.project.driveURL} target="_blank"><i className="fab fa-google-drive"></i></a>}
	              {foundDay.project.tasksURL && <a className="tool" href={foundDay.project.tasksURL} target="_blank"><i className="fab fa-jira"></i></a>}
	              {foundDay.project.uiURL && <a className="tool" href={foundDay.project.uiURL} target="_blank"><i className="fab fa-figma"></i></a>}
	          </div>
            </div>
          ):(
            <div className="container marginOnMobile" style={{
              boxShadow: '5px 5px 10px #1c1c1c, -5px -5px 10px #202020',
              borderRadius: '8px',
              background: '#1c1e22',
              marginTop: 40
            }}>
              <p>Rien de prévu aujourd'hui !</p>
            </div>
          )}
          {foundTomorrowDay ? (
            <div className="container marginOnMobile" style={{
              borderRadius: '8px',
              background: '#1c1e22',
              marginTop: 20,
              opacity: 0.5
            }}>
              <p>Demain <b>{formatDate(foundTomorrowDay.date)}</b></p>
              <h2>{foundTomorrowDay.project.client} - <span style={{fontWeight:'normal'}}>{foundTomorrowDay.project.name}</span></h2>
            </div>
          ):(
            <div className="container marginOnMobile" style={{
              borderRadius: '8px',
              background: '#1c1e22',
              marginTop: 20,
              opacity: 0.5
            }}>
              <p>Rien de prévu demain !</p>
            </div>
          )}

        </div>
        <div className="col half" style={{width:'calc(50% - 100px)', paddingTop:70}}>
          <Project user={this.props.profile}/>
        </div>
        <ReactTooltip className='tooltip' place="bottom" id="link" type="dark" effect="solid" getContent={(dataTip) => dataTip}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile
  };
}

export default connect(mapStateToProps, {})(HomePage);
