import React, { Component } from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import { createBrowserHistory } from 'history';
import { LocalizeProvider } from "react-localize-redux";

import ReactGA from 'react-ga';

// Import miscellaneous routes and other requirements
import Page from './page';
import NotFoundPage from './pages/not-found-page';
import HomePage from './pages/home';

// Import authentication related pages
import Login from './auth/login';
import Logout from './auth/logout';
import ForgotPassword from './auth/forgot_password';
import ResetPassword from './auth/reset_password';

//Import Profile pages
import Profile from './profile/profile';

// Import higher order components
import RequireAuth from './auth/require_auth';

// Import user pages
import User from './user/user';
import UserView from './user/view';
import UserAdd from './user/add';

// Import projects pages
import Project from './project/project';
import ProjectView from './project/view';
import ProjectAdd from './project/add';

// Import salary pages
import Salary from './salary/salary';

// Import planning pages
import Planning from './planning/planning';

// Import projects pages
import Expense from './expense/expense';
import ExpenseAdd from './expense/add';

// Initialize Google Analytics
ReactGA.initialize('UA-38976794-1');
function logPageView() {
  ReactGA.pageview(window.location.pathname);
}

class App extends Component {
  render() {
    const login = function login(props){
      return <Page title="Login" {...props}><Login /></Page>
    }
    const logout = function logout(props){
      return <Page title="Logout" {...props}><Logout /></Page>
    }

    return (
      <LocalizeProvider>
        <Router history={createBrowserHistory()} onUpdate={logPageView}>
          <Route exact path="/" component={RequireAuth((props) => <Page title="Home" {...props}><HomePage /></Page>)} />

          <Route path="/login" component={login} />
          <Route path="/logout" component={logout} />
          <Route path="/forgot-password" component={(props) => <Page title="ForgotPassword" {...props}><ForgotPassword /></Page>} />
          <Route path="/reset-password/:resetToken" component={ResetPassword} />

          <Route path="/profile" component={RequireAuth((props) => <Page title="Profile" {...props}><Profile /></Page>)} />

          <Route exact path="/user" component={RequireAuth((props) => <Page title="User" {...props}><User /></Page>)} />
          <Route exact path="/user/:id" component={RequireAuth((props) => <Page title="User View" {...props}><UserView /></Page>)} />
          <Route exact path="/user/add/:id" component={RequireAuth((props) => <Page title="User Add" {...props}><UserAdd /></Page>)} />

          <Route exact path="/project" component={RequireAuth((props) => <Page title="Project" {...props}><Project /></Page>)} />
          <Route exact path="/project/:id" component={RequireAuth((props) => <Page title="Project View" {...props}><ProjectView /></Page>)} />
          <Route exact path="/project/add/:id" component={RequireAuth((props) => <Page title="Project Add" {...props}><ProjectAdd /></Page>)} />

          <Route exact path="/expense" component={RequireAuth((props) => <Page title="Expense" {...props}><Expense /></Page>)} />
          <Route exact path="/expense/add/:id" component={RequireAuth((props) => <Page title="Expense Add" {...props}><ExpenseAdd /></Page>)} />

          <Route exact path="/salary" component={RequireAuth((props) => <Page title="Bulletins" {...props}><Salary /></Page>)} />

          <Route exact path="/planning" component={RequireAuth((props) => <Page title="Planning" {...props}><Planning /></Page>)} />

          {/*<Route path="*" component={NotFoundPage} />*/}
        </Router>
      </LocalizeProvider>
    );
  }
}

export default App;
