import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { resetPassword } from '../../actions/auth';
import logo from '../../assets/images/logo.png';

const form = reduxForm({
  form: 'resetPassword',
  validate,
});

function validate(formProps) {
  const errors = {};
  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");

  if (!formProps.password) {
    errors.password = 'Please enter a new password';
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = 'Please confirm new password';
  }

  if (formProps.password !== formProps.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match';
  }

  if(!strongRegex.test(formProps.passwordConfirm)) {
    errors.passwordConfirm = 'Please enter a password with all requirement';
  }

  if(!strongRegex.test(formProps.password)) {
    errors.password = 'Please enter a password with all requirement';
  }

  return errors;
}

const renderField = field => (
  <div>
    <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input} value={ null }/>
    {field.meta.touched && field.meta.error && <div className="error" style={{marginTop:'-10px', marginBottom:'20px'}}>{field.meta.error}</div>}
  </div>
);

class ResetPassword extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentWillMount() {
    if (this.props.authenticated) {
      this.context.router.push('/');
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.authenticated) {
      this.context.router.push('/');
    }
  }

  handleFormSubmit({ password }) {
    const resetToken = this.props.match.params.resetToken;
    this.props.resetPassword(resetToken, { password });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="message">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page-login">
        <div className="right-login">
          <img className="logo" src={logo}/>
          <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login" style={{top:0, animationDelay: 0}}>
            {this.renderAlert()}
            <h1>Reset your password</h1>
            <p style={{fontStyle: 'italic', color: '#999', marginTop: 0}}>Password must contain one lowercase, one number and be 8 characters long</p>
            <div className="row">
              <label className="" for="password">New Password</label>
              <Field name="password" component={renderField} type="password" placeholder="New password ..."/>
            </div>
            <div className="row">
              <label className="" for="passwordConfirm">Confirm Password</label>
              <Field name="passwordConfirm" component={renderField} type="password" placeholder="Confirm your new password ..."/>
            </div>
            <button type="submit" className="btn btn-submit">Change Password</button>
          </form>
        </div>  
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.error, message: state.auth.resetMessage };
}

export default withRouter(connect(mapStateToProps, { resetPassword })(form(ResetPassword)));
