import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserAddFile from './addFile'
import ContainerTranslation from "./../partials/containerTranslation";
import SalaryList from "./../salary/salaryList";
import ReactTooltip from 'react-tooltip';
import Project from "./../planning/project";
import _ from 'lodash'

import { CLIENT_ROOT_URL } from '../../actions/index';
import { resetUser, fetchUser, updateUser, deleteUser } from '../../actions/user';
import { resetFile } from '../../actions/file';

class UserView extends Component {

  constructor(props) {
    super(props);
    const { fetchUser, resetFile } = this.props;
    resetFile()
    fetchUser(this.props.match.params.id)
    this.state = {
      add: false
    };
  }

  componentDidUpdate(prevProps, prevState){
    var _this = this
    if(this.props.message && this.props.message === 'User successfully updated!') {
        this.props.resetFile();
        this.props.resetUser();
        this.props.fetchUser(_this.props.user._id);
    }
    if (this.props.message && this.props.message === 'User successfully removed!') {
        window.location.href = CLIENT_ROOT_URL+'/user'
    }
  }

  handleDelete(id) {
    var result = window.confirm("Voulez-vous vraiment supprimer ce user ? Cela supprimera également les séances qui y sont attachées !");
    if (result) {
      this.props.deleteUser(id)
    }
  };

  addFile(id, e) {
    e.preventDefault();
    this.setState({
      add:true
    })
  };

  closeModal(data) {
    this.setState({
      add:false
    })
  };

  render() {

    return (
      <div className="page-container view-container">
        {this.props.user && this.props.user.profile && this.props.user.profile.firstName ? (
          <div className="container" style={{textAlign:'left', marginTop: 0}}>
          <div className="tools" style={{marginTop:24}}>
              <Link to={"/user/add/"+this.props.user._id} data-for='tooluser' data-tip="user.edit">
                <div style={{display:'inline-block'}}>
                  <i className="fa fa-pen" aria-hidden="true"></i>
                </div>
              </Link>
            <a onClick={this.handleDelete.bind(this, this.props.user._id)} data-for='tooluser' data-tip="user.delete" className="Message-remove">
                <div style={{display:'inline-block'}}>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                </div>
            </a>
            <ReactTooltip className='tooltip' id='tooluser' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
            <div className="back-btn">
              <Link to="/user"><i className="fas fa-arrow-left"></i></Link>
            </div>
            <div className="user-thumbnail" style={{backgroundColor:this.props.user.profile.color}}>
              {this.props.user.profile.firstName.charAt(0)}
            </div>
            <h2 style={{
              verticalAlign: 'top',
              marginTop: 15,
              marginBottom: 0,
              display: 'inline-block'
            }}>{this.props.user.profile.firstName} {this.props.user.profile.lastName}</h2>
          </div>
        ) : (
          null
        )}
        <SalaryList user={this.props.user} withTitle={true}/>
        <div className="container" style={{textAlign:'left', marginTop: 0, paddingTop: 0}}>
            <div className="documents">
                <a className="document" onClick={this.addFile.bind(this, this.props._id)} style={{background:'#fa5c4f', color:"#ffffff"}}>
                  <i className="fas fa-plus"></i>
                  <p style={{color:"#ffffff"}}>Nouveau bulletin</p>
                </a>
            </div>
        </div>
        {this.state.add ? (
          <UserAddFile
            userId={this.props.user._id}
            closePanel={this.closeModal.bind(this)}
          />
        ) : (
          null
        )}
        <div className="container" style={{paddingTop:0, marginTop: 0}}>
          <Project user={this.props.user}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    message: state.user.message
  };
}

export default withRouter(connect(mapStateToProps, {resetUser, fetchUser, updateUser, resetFile, deleteUser})(UserView));
