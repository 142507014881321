import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PlanningView from "./planningView";
import Project from "./project";
import _ from 'lodash'

class Planning extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className="page-container">
          <div className="container" style={{textAlign:'left', padding: 0}}>
            <h1 className="page-title" style={{marginBottom:30, marginTop:4}}>PLANNING DE L'ÉQUIPE</h1><br/>
            <PlanningView />
            <Project />
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(connect(mapStateToProps, {})(Planning));
