import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ContainerTranslation from "../partials/containerTranslation";
import LanguageToggle from "./language-toggle";
import { editUser } from '../../actions/auth';

const form = reduxForm({
  form: 'register',
  validate,
});

const renderField = field => (
  <div>
    {field.meta.touched && field.meta.error && <div className="error">{field.meta.error}</div>}
    <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input}/>
  </div>
);

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!formProps.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (!formProps.password) {
    errors.password = 'Please enter a password';
  }

  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");
  if(!strongRegex.test(formProps.password)) {
    errors.password = 'Please enter a password with all requirement';
  }

  return errors;
}

class EditProfile extends Component {
  handleFormSubmit(formProps) {
    this.props.editUser(this.props.profile._id,formProps);
  }

  componentDidUpdate(prevProps) {
    if(this.props.message && this.props.message === "User successfully updated!"){
      this.props.history.push('/');
    }
  }

  changeLanguage(elm) {
    var _this= this
    console.log(elm)
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div>
          <span><strong>Error!</strong> {this.props.errorMessage}</span>
        </div>
      );
    }
  }

  renderFields() {
    if (this.props.initialValues) {
      return (
        <div>
          <div style={{marginBottom: '15px'}}>
            <label style={{marginBottom: '5px', display:'block'}}>Language</label>
            <LanguageToggle/>
          </div>
          <div>
            <label>FirstName</label>
            <Field name="firstName" component={renderField} type="text" placeholder="Your first name ..."/>
          </div>
          <div>
            <label>Name</label>
            <Field name="lastName" component={renderField} type="text" placeholder="Your last name ..."/>
          </div>
          <div>
            <label>Change your password</label>
            <p style={{fontStyle: 'italic', color: '#999', marginTop: 0}}>Your new password must contain one lowercase, one number and be 8 characters long</p>
            <Field name="password" component={renderField} type="password" placeholder="Type your password ..."/>
          </div>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
        className="container"
        style={{width: 'calc(100% - 40px)', 'paddingRight': '20px', 'paddingLeft': '20px', borderRadius: '8px', marginTop: '20px',marginBottom: '20px'}}>
        <p style={{color:'#fa5c4f', marginTop:0}}>{this.props.email}</p>
        {this.renderAlert()}
        {this.renderFields()}
        <button type="submit" className="btn btn-submit"><ContainerTranslation id={"profile.editInformation"}/></button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  let values = null

  if(state.user && state.user.profile && state.user.profile.email){
    values = state.user.profile
  }
  return {
    profile: state.user.profile,
    message: state.user.message,
    initialValues: values,
  };
}

export default connect(mapStateToProps, { editUser })(form(EditProfile));
