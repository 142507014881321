import { FETCH_PROFILE, RESET_USER, RESET_PROFILE, CREATE_USER, FETCH_USER, FETCH_STATS, FETCH_USERS, UPDATE_USER, DELETE_USER, USER_ERROR } from '../actions/types';
import _ from 'lodash';
const INITIAL_STATE = { profile: {}, user: {}, users: {}, stats:{}, message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROFILE:
      return { ...state, profile: action.payload.user, message:'', error: '' };
    case FETCH_STATS:
      return { ...state, stats: action.payload.stats, message:'', error: '' };
    case CREATE_USER:
      return { ...state, message: action.payload.message, error: '' };
    case FETCH_USER:
      return { ...state, user: action.payload.user, message:'', error: '' };
    case FETCH_USERS:
      var users = []
      _.each(action.payload.users, function(u){
        var user = _.cloneDeep(u)
        user.busyDaysObj = {}
        user.holidaysObj = {}
        user.homeofficeObj = {}
        _.each(user.busyDays, function(d){
          if(user.busyDaysObj[d.date]){
            user.busyDaysObj[d.date].push(d)
          }else{
            user.busyDaysObj[d.date] = [d]
          }
        })
        _.each(user.holidays, function(d){
            if(user.holidaysObj[d.date]){
              user.holidaysObj[d.date].push(d)
            }else{
              user.holidaysObj[d.date] = [d]
            }
        })
        _.each(user.homeoffice, function(d){
            if(user.homeofficeObj[d.date]){
              user.homeofficeObj[d.date].push(d)
            }else{
              user.homeofficeObj[d.date] = [d]
            }
        })
        users.push(user)
      })
      return { ...state, user: {}, users: users, message:'', error: '' };
    case UPDATE_USER:
      return { ...state, message: action.payload.message, error: '' };
    case DELETE_USER:
      return { ...state, message: action.payload.message, error: '' };
      case RESET_PROFILE:
        return { ...state, profile: {}, message: '', error: '' };
      case RESET_USER:
        return { ...state, user: {}, users: {}, message: '', error: '' };
    case USER_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
