//= =====================
// Auth Actions
//= =====================
export const AUTH_USER = 'auth_user',
  UNAUTH_USER = 'unauth_user',
  AUTH_ERROR = 'auth_error',
  REGISTER_USER = 'register_user',
  FORGOT_PASSWORD_REQUEST = 'forgot_password_request',
  RESET_PASSWORD_REQUEST = 'reset_password_request',
  PROTECTED_TEST = 'protected_test';

//= =====================
// User Profile Actions
//= =====================
export const FETCH_PROFILE = 'fetch_profile',
  RESET_PROFILE = 'reset_profile',
  FETCH_STATS = 'fetch_stats';

//= =====================
// Users Actions
//= =====================
export const CREATE_USER = 'create_user',
  FETCH_USERS = 'fetch_users',
  FETCH_USER = 'fetch_user',
  UPDATE_USER = 'update_user',
  DELETE_USER = 'delete_user',
  RESET_USER = 'reset_user',
  USER_ERROR = 'user_error';

//= =====================
// Files Actions
//= =====================
export const CREATE_FILE = 'create_file',
  RESET_FILE = 'reset_file',
  FILE_ERROR = 'file_error';

//= =====================
// Project Actions
//= =====================
export const CREATE_PROJECT = 'create_project',
  FETCH_PROJECTS = 'fetch_projects',
  FETCH_PROJECT = 'fetch_project',
  UPDATE_PROJECT = 'update_project',
  DELETE_PROJECT = 'delete_project',
  PROJECT_ERROR = 'project_error',
  FETCH_LIST = "fetch_list",
  RESET_PROJECTS = "reset_projects";

//= =====================
// Expense Actions
//= =====================
export const CREATE_EXPENSE = 'create_expense',
    FETCH_EXPENSES = 'fetch_expenses',
    FETCH_EXPENSE = 'fetch_expense',
    UPDATE_EXPENSE = 'update_expense',
    DELETE_EXPENSE = 'delete_expense',
    EXPENSE_ERROR = 'expense_error';

//= =====================
// Ik Actions
//= =====================
export const CREATE_IK = 'create_ik',
    FETCH_IKS = 'fetch_iks',
    FETCH_IK = 'fetch_ik',
    UPDATE_IK = 'update_ik',
    DELETE_IK = 'delete_ik',
    IK_ERROR = 'ik_error';    

//= =====================
// Days Actions
//= =====================
export const CREATE_DAY = 'create_day',
    UPDATE_DAY = 'update_day',
    DELETE_DAY = 'delete_day',
    DAY_ERROR = 'day_error',
    RESET_MESSAGE = 'reset_message'; 

//= =====================
// Page Actions
//= =====================
export const STATIC_ERROR = 'static_error';
