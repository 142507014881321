import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import _ from 'lodash'

import { updateUser, fetchUser } from '../../actions/user';
import { createFile, resetFile } from '../../actions/file';

class UserAddFile extends Component {

  constructor(props) {
    super(props);
    const { params, createFile, updateUser, fetchUser, resetFile } = this.props;

    this.state = {
      date: "",
      file: null,
      loading:false
    };
  }

  componentDidUpdate(prevProps, prevState){
    var _this = this
    if(this.props.file && !prevState.file){
      _this.setState({
        file:this.props.file._id,
        loading:false
      })
    }
    if(this.props.messageUser && this.props.messageUser === 'User successfully updated!') {
      this.props.fetchUser(_this.props.user._id);
      this.props.resetFile()
      this.props.closePanel()
    }
  }

  handleDateChange(e){
    this.setState({
      date:e.currentTarget.value
    })
  }

  handleFileChange(selectorFiles){
    var extension = selectorFiles[0].name.split('.')[selectorFiles[0].name.split('.').length-1]
    var _this = this
    if(extension.toLowerCase() === 'pdf'){
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        _this.setState({
          loading:true
        })
        _this.props.createFile({
          file: selectorFiles[0]
        })
      };
      reader.readAsBinaryString(selectorFiles[0])
    }else{
      this.setState({
        file:null
      })
    }
  }

  closePanel() {
    this.props.closePanel()
  }

  sendData() {
    if(this.state.date && this.state.file && this.props.user){
      var userId = this.props.user._id
      var salaries = _.cloneDeep(this.props.user.salaries)
      salaries.push({
        date:this.state.date,
        file:this.state.file
      })
      this.props.updateUser({
        salaries:salaries
      }, userId)
    }
  };

  renderContainer() {
    var _this = this

    return (
      <div className="container" style={{textAlign:'center', marginTop:0}}>
        {!this.state.file ? (
          <>
            <label className="" htmlFor="file">Fichier</label>
            <input name="file" type="file" id="file" accept=".pdf" onChange={ (e) => this.handleFileChange(e.target.files) } style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}/>
          </>
        ):(
          <>
            <label className="" htmlFor="date">Mois du bulletin</label>
            <input name="date" type="date" value={this.state.date} placeholder="Date..." onChange={ (e) => this.handleDateChange(e) } style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}/>
            <p>
              {this.props.file.name}
            </p>
            {this.state.date && <a className="btn" style={{marginTop:'10px'}} onClick={ (e) => this.sendData(e) }><i className="fas fa-thumbs-up"></i><ContainerTranslation id={"salary.valid"}/></a>}
          </>
        )}

        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}

      </div>
    );
  }

  renderAlert() {
    if (this.props.messageFile) {
      return (
        <div className="message">
          {this.props.messageFile}
        </div>
      );
    }
    if (this.props.messageUser) {
      return (
        <div className="message">
          {this.props.messageUser}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container add-back">
        <div className="page-container add">
          <div className="page-container-header">
            <a className="add-btn btn" onClick={this.closePanel.bind(this)}><i className="fas fa-times"></i></a>
          </div>
          {this.renderContainer()}
          {this.renderAlert()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    file: state.file.file,
    messageFile: state.file.message,
    messageUser: state.user.message
  };
}

export default connect(mapStateToProps, {createFile, updateUser, fetchUser, resetFile})(UserAddFile);
